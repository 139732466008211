import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col, Form } from "react-bootstrap";
import Select, { components } from 'react-select';
import _ from "lodash";
import Icon from "components/Icons/Icon";
import CopyToClipboard from "components/Site/SiteView/SiteOverview/Tabs/CopyToClipboard";
import CountriesDropdown from "components/Common/CountriesDropdown/CountriesDropdown";
import { darkModeSelectStyles } from "components/Common/CustomStyles/darkModeSelectStyles";
import conxtdOut from "apis/conxtdOut";
import moment from "../../../../../node_modules/moment-timezone/index";
import "./AssetEditor.scss";

function SiteDetails({ 
  siteDetails, 
  companyId, 
  editMode,
  formSiteRefPrefix,
  setDisabledButton,
  transmitterRefTitle,
  siteRefTitle,
  invalid,
  invalidSite,
  setEditData,
  changeDetected,
  setChangeDetected,
  setSiteRefShort,
  setSiteRefLong,
  invalidSiteRef,
  setInvalidSiteRef,
  signallingTypeFeedback, 
  setSignallingTypeFeedback,
  existingSites,
  setExistingSites,
  initialiseFormDataTrigger,
  setInitialiseFormDataTrigger,
  setInvalidComponent,
  mobile_app_id,
  setMobileAppId,
  slug,
  setSlug,
  renderOnLoad,
  initSiteRefTitle,
  noAlarmAssociation,
  setNoAlarmAssociation,
  initInvalidSiteDetails,
  setInitInvalidSiteDetails,
  companyName,
  setCheckCSLChange,
 }) {

  const [site_ref, setSiteRef] = useState("");
  const [siteRefWithoutPrefix, setSiteRefWithoutPrefix] = useState("");
  const [site_name, setSiteName] = useState("");
  const [transmitter_ref, setTransmitterRef] = useState("");
  const [mctcluster_id, setMctClusterId] = useState("");
  const [mctClusterName, setMctClusterName] = useState("");
  const [mctClusterList, setMctClusterList] = useState([]);
  const [mctGlobalOptions, setMctGlobalOptions] = useState([]);
  const [mctDedicatedOptions, setMctDedicatedOptions] = useState([]);
  const [arcmap_id, setArcMapId] = useState("");
  const [contract_number, setContractNumber] = useState("");
  const [product_description, setProductDescription] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [town, setTown] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country_code, setCountryCode] = useState("");
  const [timezone, setTimezone] = useState("");  
  const [associatedAlarmConnections, setAssociatedAlarmConnections] = useState([]);
  const [signallingTypeName, setSignallingTypeName] = useState();

  const [formData, setFormData] = useState({
    mobile_app_id,
    site_ref,
    transmitter_ref,
    company_name: site_name,
    address1,
    address2,
    town,
    country_code,
    postcode,
    timezone,
    arcmap_id,
    mctcluster_id,
    contract_number,
    product_description,
  });

  // REDUX SELECTORS ------
  const darkMode = useSelector((state) => state.app.darkMode)
  // -------------------
  
  // STATE MANAGEMENT ------
  // toggle states
  // const [showTimezones, setShowTimezones] = useState(false);

  // populate form state
  const [formMobileApps, setFormMobileApps] = useState([]);
  // const [formTimezones, setFormTimezones] = useState([]);

  // form validation
  const [initDetails, setInitDetails] = useState();

  // ---------

  // Custom Select Component and Options -----
  const CustomOption = (props) => {
    const { data } = props;
    const checkMobileAppId = data.value?.mobile_app_id === siteDetails?.mobile_app_id;
    const checkMctClusterId = data.value?.mct_cluster_id === siteDetails?.mct_cluster_id;
    const checkSlug = data.value?.slug === siteDetails?.slug;
    return (
      <components.Option {...props}>
        <div className='d-flex align-items-center pl-1 pr-1'>
          <span className={
            checkMobileAppId
            && checkMctClusterId
            && checkSlug
            ? `current-sig-type-label` : ''
          }
            >{data.label}</span>
          {data.associated_icon && <span className='ml-3'>{data.associated_icon}</span>}
        </div>
      </components.Option>
    );
  };
  // -----------

  // USE EFFECT -------
  useEffect(() => {
    if (siteDetails && Object.keys(siteDetails).some(value => value)) {
      setSiteName(siteDetails.site_name);
      setSiteRef(siteDetails.site_ref);
      setSiteRefWithoutPrefix(() => {
        return formSiteRefPrefix ? String(siteDetails.site_ref).substring(2) : siteDetails.site_ref
      })
      setTransmitterRef(siteDetails.transmitter_ref);
      setMctClusterId(siteDetails.mct_cluster_id);
      setMctClusterName(siteDetails.mct_cluster_name);
      setArcMapId(siteDetails.arcmap_id);
      setMobileAppId(siteDetails.mobile_app_id);
      setContractNumber(siteDetails.contract_number);
      setProductDescription(siteDetails.product_description);
      setAddress1(siteDetails.address1);
      setAddress2(siteDetails.address2);
      setTown(siteDetails.town);
      setPostcode(siteDetails.postcode);
      setCountryCode(() => {
        if (siteDetails.country_code === 'UK' || siteDetails.country_code === 'GB') {
          return 'GB';
        }
        return siteDetails.country_code;
      });
      setTimezone(siteDetails.timezone);
      setSlug(siteDetails.slug);
      setSignallingTypeName(
        Object.entries(siteDetails?.mobile_apps_list).filter(([key, value]) => value?.mobile_app_id === mobile_app_id && key)?.[0]?.[0]
      );      
      setInitDetails({
        site_ref: siteDetails.site_ref,
        mobile_app_id: siteDetails.mobile_app_id,
        transmitter_ref: siteDetails.transmitter_ref,
        site_name: siteDetails.site_name,
        address1: siteDetails.address1,
        address2: siteDetails.address2,
        town: siteDetails.town,
        country_code: siteDetails.country_code === 'UK' ? 'GB' : siteDetails.country_code,
        postcode: siteDetails.postcode,
        arcmap_id: siteDetails.arcmap_id,
        mctcluster_id: siteDetails.mct_cluster_id,
        mctClusterName: siteDetails.mct_cluster_name,
        contract_number: siteDetails.contract_number,
        product_description: siteDetails.product_description,
        timezone: siteDetails.timezone,
      });
      setInitInvalidSiteDetails({
        siteRef: { null: siteDetails.site_ref && false },
        transmitter_ref: { null: siteDetails.transmitter_ref && false },
        mobile_app_id: { null: siteDetails.mobile_app_id && false },
        // mctcluster_id: { null: siteDetails.mct_cluster_id && false },
        arcmap_id: { null: siteDetails.arcmap_id && false },
        companyId: { null: companyId && false },
        company_name: { null: siteDetails.site_name && false },
        address1: { null: siteDetails.address1 && false },
        postcode: { null: siteDetails.postcode && false },
        country_code: { null: siteDetails.country_code && false },
      });
      setFormData({
        site_ref: siteDetails.site_ref,
        mobile_app_id: siteDetails.mobile_app_id,
        transmitter_ref: siteDetails.transmitter_ref,
        site_name: siteDetails.site_name,
        address1: siteDetails.address1,
        address2: siteDetails.address2,
        town: siteDetails.town,
        country_code: siteDetails.country_code === 'UK' ? 'GB' : siteDetails.country_code,
        postcode: siteDetails.postcode,
        arcmap_id: siteDetails.arcmap_id,
        mctcluster_id: siteDetails.mct_cluster_id,
        contract_number: siteDetails.contract_number,
        product_description: siteDetails.product_description,
        timezone: siteDetails.timezone,
      });
      setAssociatedAlarmConnections(
        Object.values(siteDetails?.associated_alarm_connections)?.map(value => value.mobile_app_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetails])

  useEffect(() => {
    if (siteDetails?.mobile_app_id) {
      setFormMobileApps(
        Object.entries(siteDetails?.mobile_apps_list)?.map(([key, value]) => ({
          label: key?.charAt(0).toUpperCase() + key?.slice(1),
          value: {
            mobile_app_id: value.mobile_app_id,
            mct_cluster_id: value?.mct_cluster_id ?? null,
            slug: value?.slug ?? null,
          },
          associated_icon: (
            <Icon
              className={`far ${associatedAlarmConnections.includes(value.mobile_app_id) 
                ? 'fa-check-circle signalling-icon associated' : 'fa-times-circle signalling-icon not-associated'}`}
            />
          ),
        }))
      );
    }
  }, [associatedAlarmConnections, siteDetails, setFormMobileApps])

  const initialiseFormData = useCallback(() => {
    if (siteDetails && initDetails) {
      setSiteName(siteDetails.site_name);
      setSiteRef(siteDetails.site_ref);
      setSiteRefWithoutPrefix(() => {
        return formSiteRefPrefix ? String(siteDetails.site_ref).substring(2) : siteDetails.site_ref
      })
      setTransmitterRef(siteDetails.transmitter_ref);
      setMctClusterId(siteDetails.mct_cluster_id);
      setMctClusterName(siteDetails.mct_cluster_name)
      setArcMapId(siteDetails.arcmap_id);
      setMobileAppId(siteDetails.mobile_app_id);
      setContractNumber(siteDetails.contract_number);
      setProductDescription(siteDetails.product_description);
      setAddress1(siteDetails.address1);
      setAddress2(siteDetails.address2);
      setTown(siteDetails.town);
      setPostcode(siteDetails.postcode);
      setCountryCode(siteDetails.country_code);
      setTimezone(siteDetails.timezone);
      setSlug(siteDetails?.slug);
      setFormData(initDetails);
      setSignallingTypeFeedback(false);
      setNoAlarmAssociation(false);
      setInvalidComponent(initInvalidSiteDetails);
      setExistingSites(false);
    }
  }, [
    siteDetails,
    initDetails,
    formSiteRefPrefix,
    setMobileAppId,
    setSlug,
    setSignallingTypeFeedback,
    setNoAlarmAssociation,
    initInvalidSiteDetails,
    setInvalidComponent,
    setExistingSites,
  ])

  useEffect(() => {
    if (initialiseFormDataTrigger) {
      initialiseFormData();
      setInitialiseFormDataTrigger(false);
    }
  }, [initialiseFormDataTrigger, initialiseFormData, setInitialiseFormDataTrigger])

  useEffect(() => {
    setFormData({
      site_ref,
      mobile_app_id,
      transmitter_ref,
      site_name,
      address1,
      address2,
      town,
      country_code,
      postcode,
      arcmap_id,
      mctcluster_id,
      contract_number,
      product_description,
      timezone,
    });
}, [
  site_ref,
  site_name,
  transmitter_ref,
  arcmap_id,
  mctcluster_id,
  mobile_app_id,
  contract_number,
  product_description,
  address1,
  address2,
  town,
  postcode,
  country_code,
  timezone,
])

  useEffect(() => {
    const { site_name, ...rest } = formData;
    const updatedData = { ...rest, company_name: site_name };
    setEditData(updatedData);
  }, [formData, setEditData])

  useEffect(() => {
    invalid ? setDisabledButton("disabled") : setDisabledButton("");
  }, [invalid, invalidSite, invalidSiteRef, setDisabledButton])

  // ---------

  // FUNCTIONS --------
  const handleNullChange = (name, value) => {
    setInvalidComponent((prevState) => ({
      ...prevState,
      [name]: { null: value ? false : true},
    }));
  };

  const checkExistingSites = useCallback((sudoSiteRef) => {
    if (sudoSiteRef && companyId) {
      const getExistingSites = conxtdOut.get(`/ServiceDesk/getExistingSites/${sudoSiteRef}/${companyId}/${mctcluster_id ?? ''}?currentSiteRef=${initDetails.site_ref}`)
      getExistingSites.then((response) => {
        if (!existingSites && mobile_app_id !== siteDetails?.mobile_app_id && site_ref === siteDetails?.site_ref) {
          setExistingSites('company');
        } else {
          setExistingSites(response.data?.['sudoSites'])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, initDetails, setExistingSites, siteDetails, mobile_app_id, site_ref]);

  const debouncedCheckExistingSites = useMemo(
    () => _.debounce(checkExistingSites, 300),
    [checkExistingSites]
  );

  useEffect(() => {
    if (site_ref && changeDetected) {
      debouncedCheckExistingSites(site_ref);
      if (existingSites) {
        setInvalidSiteRef(true);
      } else {
        setInvalidSiteRef(false);
      }
    } else {
      setInvalidSiteRef(false);
    }

    if (!site_ref && changeDetected) {
      setExistingSites(false);
    }

    return () => {
      debouncedCheckExistingSites.cancel();
    };
  }, [
    site_ref, 
    existingSites, 
    setInvalidSiteRef, 
    signallingTypeFeedback, 
    debouncedCheckExistingSites, 
    changeDetected,
    mobile_app_id, 
    setExistingSites
  ]);

  useEffect(() => {
    if (initDetails && formData) {
      if (Object.values(formData).some(value => value) && Object.keys(initDetails).some(value => value)) {
        for (let key of Object.keys(formData)) {
          if (formData[key] !== initDetails[key]) {
            setChangeDetected(true);
            return;
          }
        }
        setChangeDetected(false);
        return;
      }
    }
  }, [
    formData,
    siteDetails,
    initDetails,
    site_ref,
    site_name,
    transmitter_ref,
    arcmap_id,
    mobile_app_id,
    contract_number,
    product_description,
    address1,
    address2,
    town,
    postcode,
    country_code,
    timezone,
    setChangeDetected,
    setExistingSites,
  ]);

  const getTimezoneByCountry = useCallback((country) => {
    const timezone = moment.tz.zonesForCountry(country);
    setTimezone(timezone[0]);
    // setShowTimezones(false);
    // if (country_code === 'US' && timezone.length > 1) {
    //   const zones = timezone.map(zone => ({label: zone.split('America/'), value: zone}))
    //   setFormTimezones(zones);
    //   setShowTimezones(true);
    // } else {
    //   setTimezone(timezone[0]);
    //   setShowTimezones(false);
    // }
    return timezone;
  }, [])

  useEffect(() => {
    if (country_code) {
      getTimezoneByCountry(country_code === 'UK' ? 'GB' : country_code);
    }
  }, [country_code, getTimezoneByCountry])

  useEffect(() => {
    setSiteRef(String(formSiteRefPrefix + siteRefWithoutPrefix));
  }, [siteRefWithoutPrefix, formSiteRefPrefix]);
  
  useEffect(() => {
    siteRefWithoutPrefix?.length > 0 ? setSignallingTypeFeedback(false) : setSignallingTypeFeedback(true);
  }, [siteRefWithoutPrefix, setSignallingTypeFeedback])

  useEffect(() => {
    if (siteDetails?.site_ref && mobile_app_id === siteDetails?.mobile_app_id) {
      setSiteRefWithoutPrefix(() => {
        return formSiteRefPrefix ? String(siteDetails.site_ref).substring(2) : siteDetails.site_ref
      })
    }
    setNoAlarmAssociation(!(associatedAlarmConnections.includes(mobile_app_id)));
  }, [siteDetails, mobile_app_id, formSiteRefPrefix, associatedAlarmConnections, setNoAlarmAssociation])

  useEffect(() => {
    if (slug === 'ajax' && arcmap_id) {
      const getAjaxMcts = conxtdOut.get(`/ServiceDesk/getAjaxMctOptions/${arcmap_id}`)
      getAjaxMcts.then((response) => {
        const ajaxMcts = response?.data?.ajaxMctOptions;
        const formatMctOptions = (optionType) => {
          return Object.entries(optionType)?.map(([key, value]) => ({
            label: key,
            value: value ?? null,
          }))
        }
        setMctGlobalOptions(formatMctOptions(ajaxMcts.global));
        setMctDedicatedOptions(formatMctOptions(ajaxMcts.dedicated));
      })
    }
  }, [slug, arcmap_id])

  useEffect(() => {
    setMctClusterList([
      {
        label: "Global",
        options: mctGlobalOptions.sort((a, b) => a.label.localeCompare(b.label))
      },
      {
        label: "Dedicated",
        options: mctDedicatedOptions.sort((a, b) => a.label.localeCompare(b.label))
      }
    ])
  }, [mctDedicatedOptions, mctGlobalOptions])

  // checks if new mobile app is csl pro so user can be warned of changes to existing pin mapping profile
  useEffect(() => {
    if (siteDetails?.slug !== 'csl-pro') {
      const cslProMobileApp = mobile_app_id
        && formMobileApps?.find(app => (
          app?.value?.slug === 'csl-pro' && app?.value?.mobile_app_id === mobile_app_id
        ))
      setCheckCSLChange(slug && slug === cslProMobileApp?.value?.slug);
    }
  }, [mobile_app_id, slug, formMobileApps, setCheckCSLChange])

  // finds the correct label-value object for ajax mct cluster ids (needed as the array is nested)
  const findSelectedOption = useMemo(() => {
    if (!mctClusterList || !mctcluster_id) return null;
    if (mctClusterName && mctcluster_id && mctcluster_id === siteDetails?.mct_cluster_id) {
      return {
        label: mctClusterName,
        value: mctcluster_id,
      }
    }
    for (const group of mctClusterList) {
      const foundOption = _.find(group.options, { value: mctcluster_id });
      if (foundOption) return foundOption;
    }
    return null;
  }, [mctClusterList, mctcluster_id, siteDetails?.mct_cluster_id, mctClusterName]);


  return (
    <>
      {/* Main wrapper */}
      <div className='w-100' data-intercom-target={"site-details-wrapper"}>
        <div className={`d-flex justify-content-center ${darkMode ? "darkMode" : ""} w-100 card-body p-0`}>
          <Row className="w-100">
            <Col className="p-0">
              <div>
                <Form.Text className="page-sub-header">Signalling Information</Form.Text>
                <Row>
                  <Col xs={12} md={6} className="detail-field">
                    <div className="d-flex flex-row">
                      <Form.Label className="form-input-labels">Signalling Type *</Form.Label>
                      {(!editMode && signallingTypeName) && <CopyToClipboard id={"mobile_app_id"} copyText={signallingTypeName} />}
                    </div>
                    <Select
                      data-intercom-target={"signalling-type-select"}
                      name="mobile_app_id"
                      id="mobile_app_id"
                      className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                      classNamePrefix="signalling-type"
                      options={formMobileApps?.sort((a, b) => a.label.localeCompare(b.label))}
                      components={{ Option: CustomOption }}
                      value={
                        mobile_app_id
                          ? formMobileApps?.find(app => (
                            app.value.mct_cluster_id === mctcluster_id && app.value.mobile_app_id === mobile_app_id
                          ) || app.value.mobile_app_id === mobile_app_id)
                          : ""
                      }
                      maxMenuHeight={400}
                      required
                      onChange={(e) => {
                        setMobileAppId(e ? e.value.mobile_app_id : null)
                        setMctClusterId(e ? e.value.mct_cluster_id : null)
                        setSlug(e ? e.value.slug : null)
                        handleNullChange("mobile_app_id", e ? e.value.mobile_app_id : null)
                        // handleNullChange("mct_cluster_id", e ? e.value.mct_cluster_id : null)
                        if (e.value.mobile_app_id !== siteDetails?.mobile_app_id) {
                        setSiteRef("")
                        setSiteRefWithoutPrefix("")
                        setSignallingTypeFeedback(true)
                        } else if (siteDetails?.site_ref && e.value.mobile_app_id === siteDetails?.mobile_app_id) {
                          setSignallingTypeFeedback(false)
                          setExistingSites(false);
                        }
                      }}
                      styles={{
                        ...(darkMode ? darkModeSelectStyles : {})
                      }}
                    />
                    <Form.Control.Feedback type="warning" className="trigger-feedback-invalid">
                      <div className={`feedback-container-${noAlarmAssociation ? "after" : "before"}`}>
                          <div className="d-flex align-items-center flex-wrap justify-content-between">
                            <div className="feedback-text-stack">
                              This Signalling Type is not associated with <span className="font-weight-bold">{companyName}.</span>
                              <div className="mt-1 p-0">Contact the team to learn more.</div>
                            </div>
                            <Button className="feedback-button" variant="primary">Open Chat</Button>
                          </div>
                      </div>
                    </Form.Control.Feedback>
                  
                  {/* Mct Cluster ID / List */}
                  </Col>
                  {(slug === 'webwayone') &&
                    <Col xs={12} md={6}
                      className="detail-field"
                      id="mct_cluster_name"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label className="form-input-labels">System *</Form.Label>
                        {!editMode && <CopyToClipboard id={"mct_cluster_name"} copyText={mctClusterName} />}
                      </div>
                      <Form.Control
                        data-intercom-target={"mct-cluster-id"}
                        className='no-click'
                        onChange={() => {return}}
                        value={mctClusterName}
                      />
                    </Col>
                  }
                  
                  {(slug === 'ajax') &&
                    <Col xs={12} md={6}
                      className="detail-field"
                      id="mct_cluster_list"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>System *</Form.Label>
                        {(!editMode && mctClusterName) && <CopyToClipboard id={"mct_cluster_name"} copyText={mctClusterName} />}
                      </div>
                      <Select
                        name="mct_cluster_list"
                        options={mctClusterList}
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                        classNamePrefix="country-select"
                        maxMenuHeight={400}
                        required
                        value={findSelectedOption}
                        onChange={(e) => {
                          setMctClusterId(e ? e.value : null);
                          setMctClusterName(e ? e.label : null)
                          handleNullChange("mct_cluster_id", e ? e.value : null)
                        }}
                        styles={darkMode ? darkModeSelectStyles : {}}
                      />
                    </Col>
                  }
                </Row>

                <Row>
                  <Col xs={12} md={6}
                    id="site_ref"
                    className="site-ref-container d-flex flex-column detail-field"
                  >
                    <div className="d-flex flex-row">
                      <Form.Label>{siteRefTitle} *</Form.Label>
                      {(!editMode && site_ref) && <CopyToClipboard id={"site_ref"} copyText={site_ref} />}
                    </div>
                    <div className={`d-flex align-items-center flex-wrap`}>
                      {siteRefTitle !== "Site Ref" &&
                        <div id="siteref-prefix" className="col-2">
                          <div className="d-flex align-items-center justify-content-center font-weight-bold">{formSiteRefPrefix}</div>
                        </div>
                      }
                      <Form.Control
                        data-intercom-target={"site-ref-input-field"}
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"} col ${siteRefTitle !== "Site Ref" && "siteref-field"} ${(signallingTypeFeedback && !renderOnLoad) && "site-ref-warning"}`}
                        type="text"
                        name="site_ref"
                        value={renderOnLoad ? "Loading..." : siteRefWithoutPrefix}
                        placeholder={`Current ${initSiteRefTitle}: ${siteDetails?.site_ref}`}
                        maxLength={`${siteRefTitle !== "Site Ref" && 6}`}
                        required
                        onChange={(e) => {
                          setSiteRefWithoutPrefix(e.target.value)
                          let length = e.target.value.length;
                          if (formSiteRefPrefix === "21") {
                            if (length < 6) {
                              setSiteRefShort(true)
                            } else if (length > 6) {
                              setSiteRefLong(true);
                            } else {
                              setSiteRefShort(false);
                              setSiteRefLong(false);
                            }
                          } else {
                            setSiteRefShort(false);
                            setSiteRefLong(false);
                          }
                          handleNullChange("site_ref", e.target.value)
                        }}
                      />
                      </div>
                      <Form.Control.Feedback type="warning" className="trigger-feedback-invalid">
                      <div className={`feedback-container-${existingSites || signallingTypeFeedback ? "after" : "before"}`}>
                          {(() => {
                            if (existingSites === 'company') {
                              return <div className="feedback-text-stack">This {siteRefTitle} is already taken. Please try again.</div>
                            } else  if (existingSites === 'all') {
                                return (
                                  <div className="d-flex align-items-center flex-wrap justify-content-between col-12 m-0 p-0">
                                    <div className="feedback-text-stack">
                                      <div>Something doesn't seem right. Need help? Contact the team now.</div>
                                      {/* <div>Need help? Contact the team now.</div> */}
                                    </div>
                                    <Button className="feedback-button" variant="primary">Open Chat</Button>
                                  </div>
                                )
                            } else if (!existingSites && signallingTypeFeedback) {
                              return <div className="feedback-text-stack">Update your {siteRefTitle} before continuing.</div>
                            } else {
                              return
                            }
                          })()}
                        </div>
                      </Form.Control.Feedback>
                  </Col>
                  <Col xs={12} md={6}
                    id="transmitter_ref"
                    className="detail-field"
                  >
                    <div className="d-flex flex-row">
                      <Form.Label>{transmitterRefTitle} *</Form.Label>
                      {(!editMode && transmitter_ref) && <CopyToClipboard id={"transmitter_ref"} copyText={transmitter_ref} />}
                    </div>
                    <Form.Control
                      data-intercom-target={"transmitter-ref-input-field"}
                      className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                      name="transmitter_ref"
                      value={transmitter_ref ? transmitter_ref : ""}
                      required
                      onChange={(e) => {
                        setTransmitterRef(e.target.value)
                        handleNullChange("transmitter_ref", e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </div>

              {/* Row Two Items */}
              <div>
              <Form.Text className="page-sub-header">Site Address</Form.Text>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}
                      id="site_name"
                      className="detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>Site Name *</Form.Label>
                        {(!editMode && site_name) && <CopyToClipboard id={"site_name"} copyText={site_name} />}
                      </div>
                      <Form.Control
                        data-intercom-target={"site-name-input-field"}
                        type="text"
                        name="company_name"
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                        value={site_name ? site_name : ""}
                        onChange={(e) => {
                          handleNullChange("company_name", e.target.value)
                          setSiteName(e.target.value)
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}
                      id="address_1"
                      className="detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>Address 1 *</Form.Label>
                        {(!editMode && address1) && <CopyToClipboard id={"address_1"} copyText={address1} />}
                      </div>
                      <Form.Control
                        data-intercom-target={"address-1-input-field"}
                        type="text"
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                        name="address1"
                        value={address1 ? address1 : ""}
                        required
                        onChange={(e) => {
                          setAddress1(e.target.value)
                          handleNullChange("address1", e.target.value)
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}
                      id="address_2"
                      className="detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>Address 2</Form.Label>
                        {(!editMode && address2) && <CopyToClipboard id={"address_2"} copyText={address2} />}
                      </div>
                      <Form.Control
                        data-intercom-target={"address-2-input-field"}
                        type="text"
                        name="Custom Group Ref"
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                        value={address2 ? address2 : ""}
                        onChange={(e) =>
                          setAddress2(e.target.value)
                        }
                      />
                    </Col>
                    <Col xs={12} md={6}
                      id="town"
                      className="detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>Town</Form.Label>
                        {(!editMode && town) && <CopyToClipboard id={"town"} copyText={town} />}
                      </div>
                      <Form.Control
                        data-intercom-target={"town-input-field"}
                        type="text"
                        name="Custom Group Ref"
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                        value={town ? town : ""}
                        onChange={(e) =>
                          setTown(e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}
                      id="postcode"
                      className="detail-field"
                    >
                      <div className="d-flex flex-row">
                        <Form.Label>Postcode *</Form.Label>
                        {(!editMode && postcode) && <CopyToClipboard id={"postcode"} copyText={postcode} />}
                      </div>
                      <Form.Control
                        data-intercom-target={"postcode-input-field"}
                        type="text"
                        className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                        name="postcode"
                        value={postcode ? postcode : ""}
                        required
                        onChange={(e) => {
                          setPostcode(e.target.value)
                          handleNullChange("postcode", e.target.value)
                        }}
                      />
                    </Col>
                    <CountriesDropdown 
                      setCountryCode={setCountryCode}
                      handleNullChange={handleNullChange}
                      clipboard={true}
                      editMode={editMode}
                      country_code={country_code}
                    />
                  </Row>
                </Form.Group>
              </div>

              {/* Company Fields */}
              <div>
                <Form.Text className="page-sub-header">Company Fields</Form.Text>
                <Row>
                  <Col xs={12} md={6}
                    id="contract_number"
                    className="detail-field"
                  >
                    <div className="d-flex flex-row">
                      <Form.Label>Contract Number</Form.Label>
                      {(!editMode && contract_number) && <CopyToClipboard id={"contract_nuber"} copyText={contract_number} />}
                    </div>
                    <Form.Control
                      data-intercom-target={"contract-number-input-field"}
                      type="text"
                      className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                      name="contract_number"
                      value={contract_number ? contract_number : ""}
                      onChange={(e) =>
                        setContractNumber(e.target.value === "" ? null : e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={12} md={6} 
                    className="detail-field"
                  >
                    <div className="d-flex flex-row">
                      <Form.Label>Product Description</Form.Label>
                      {(!editMode && product_description) && <CopyToClipboard id={"product_description"} copyText={product_description} />}
                    </div>
                    <Form.Control
                      data-intercom-target={"product-description-input-field"}
                      className={`${!editMode ? "site-details-read-only" : "edit-mode"}`}
                      name="product_description"
                      value={product_description ? product_description : ""}
                      onChange={(e) =>
                        setProductDescription(e.target.value === "" ? null : e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default SiteDetails
