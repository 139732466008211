import { createSelector } from "reselect";
import get from "lodash/get";

import { getRouterParams } from "services/redux/selectors/app/";

import {
  getEnterprises,
  getEnterpriseFromRoute,
} from "services/redux/selectors/enterprises";

import * as filters from "services/filters/";

import { getEnterpriseId } from "services/redux/selectors/reports/router";

import GROUPS from "constants/GROUPS";
import { devMode } from "./index";

export const getGroups = createSelector(
  [(state) => state.groups.data, getEnterpriseId],
  (groups, enterprise_id) => {
    // Return the groups for the selected enterprise
    return groups[enterprise_id] || [];
  }
);
export const getGroupsTree = (state) => state.groups.data.groupsTree;

export const getGroupsTreeName = (state, parent_id) => {
  const groupsTree = state.groups.data.groupsTree;
  //console.log(groupsTree.find((tree) => tree.group_id === 308));

  return groupsTree.find((tree) => tree.group_id === parent_id);
};

export const getGroupsTreeOptions = createSelector(
  [getGroupsTree],
  (groupstree) => {
    return groupstree.map((group) => {
      // replace the leading underscores that indicate the nesting of the groups with 3 spaces each
      let formatName = "";
      let remainingText = group.group_name;

      for (let i=0; i<group.group_name.length; i++) {
        if (group.group_name[i] === "_") {
          formatName = formatName + "   ";
          remainingText = group.group_name.slice(i+1);
        } else {
          break;
        }
      }
      formatName = formatName + remainingText;

      return (
        { value: group.group_id, label: formatName }
      );
    })
  }
);

export const flattenGroups = (groups) => {
  return groups.reduce((acc, group) => {
    if (group.children.length > 0) {
      return [...acc, group, ...flattenGroups(group.children)];
    }
    return [...acc, group];
  }, []);
};

export const getGroupsLoading = (state) => state.groups.loading;

export const getGroupById = (groups, id) => {
  if (!groups) return undefined;

  // Try to find the group at this level
  let foundGroup = groups.find((group) => group.id === id);
  // This isn't the group, search children
  if (!foundGroup) {
    for (let group of groups) {
      // Start again for each group's children
      foundGroup = getGroupById(group.children, id);
      if (typeof foundGroup !== "undefined") return foundGroup;
    }
  }
  // Found the group
  return foundGroup;
};

// Build readable paths for this group's groups
export const getGroupPath = (groups, group) => {
  const path = [getGroupById(groups, group.id).name];

  // Add paths while there is still a valid parent
  let parent = getGroupById(groups, group.parent_id);

  while (typeof parent !== "undefined") {
    path.unshift(parent.name);
    parent = getGroupById(groups, parent.parent_id);
  }
  return path;
};
export const getGroupPathById = (groups, id) => {
  const group = getGroupById(groups, id);

  return group ? getGroupPath(groups, group) : null;
};

// TODO: make the above function work like this
// (include the group id)
export const getGroupPathWithId = (groups, group) => {
  const path = [getGroupById(groups, group.id)];

  // Add paths while there is still a valid parent
  let parent = getGroupById(groups, group.parent_id);

  while (typeof parent !== "undefined") {
    path.unshift(parent);
    parent = getGroupById(groups, parent.parent_id);
  }
  return path;
};
export const getGroupPathWithIdById = (groups, id) => {
  const group = getGroupById(groups, id);

  return group ? getGroupPathWithId(groups, group) : null;
};

export const getGroupPathFromSite = (groups, site) => {
  const groupId = get(site, "enterprise_groups.0.id");

  const sitePath = site.path && site.path.map(({ name }) => name);

  const path = sitePath || getGroupPathById(groups, groupId);

  if (path) return path.join(GROUPS.GROUP_PATH_DELIMITER);
};

export const getGroupFromRoute = createSelector(
  [getRouterParams, getGroups, getEnterprises, getEnterpriseFromRoute],
  (params, groups, enterprises, enterprise) => {
    let { group_id } = params;

    if (enterprises.length === 0 || groups.length === 0) {
      return null;
    }

    // Use the enterprise name, it's more consistent
    if (group_id === "root") {
      // const enterprise = getEnterpriseById(enterprises, enterprise_id);

      // group_id = enterprise.root_group_id;

      // Can't access root group details because they aren't
      //  in groups from enterprise structure so generate
      //  a root group here from enterprise.
      // This is actually preferable over root group
      return {
        name: enterprise.name,
        id: enterprise.root_group_id,
        children: groups,
      };
    }

    return getGroupById(groups, parseInt(group_id)) || null;
  }
);

export const getCurrentGroup = (state) => state.groups.current;

/**
 * Applies filters to sites instead of group children
 *
 * @param {*} currentGroup
 */
const getCurrentGroupFilteredSites = (currentGroup) => {
  if (!currentGroup.sites) return currentGroup;

  const timeframe = currentGroup.timeframe || 24;

  // Loop through the current groups children
  const sites = currentGroup.sites.filter((site) => {
    // Filtering through each existing filter
    return currentGroup.filters.reduce((keep, filter) => {
      // const { key, filterGroup, name } = filter;
      const { key, filterGroup } = filter;

      let currentKeep = false;

      // Custom filtering here because sites
      // is in a different format to groups
      switch (filterGroup) {
        case "stats.alarmColours":
          const alarmLastColour = get(site, "alarmLast.colour");
          currentKeep = alarmLastColour === key;

          // Couldn't access latest alarm - it's "unknown"
          // so, keep if our key is unknown
          if (!alarmLastColour && key === "unknown") {
            currentKeep = true;
          }

          break;
        case "stats.openClosed":
          currentKeep =
            get(site, "zoneState.state") ===
            key[0].toUpperCase() + key.slice(1);
          break;
        case "stats.countHighAlarms":
          // High activity is true if there are more than 4
          // alarms per hour in average
          currentKeep = get(site, "alarmCount") > timeframe * 4;
          break;
        default:
      }

      // Apply each filter here based on type
      return keep && currentKeep;
    }, true);
    //.reduce((acc, item) => item + acc, 0);
  });

  return { ...currentGroup, sites };
};

export const getCurrentGroupFiltered = createSelector(
  [getCurrentGroup],
  (currentGroup) => {
    if (!currentGroup.children) return currentGroup;
    // No children - this may be a group with site children
    if (currentGroup.children.length === 0)
      return getCurrentGroupFilteredSites(currentGroup);

    // Loop through the current groups children
    const children = currentGroup.children.filter((child) => {
      // Filtering through each existing filter
      return currentGroup.filters.reduce((keep, filter) => {
        let { type, value, key, filterGroup } = filter;

        // Get the filter calculator function based on filter type
        const filterFn = filters[type];

        let currentKeep = false;
        // Text, can match multiple keys
        if (filterGroup === "text") {
          // Create something we can iterate over for consistency
          const keys = Array.isArray(key) ? key : [key];
          // Select each value to compare
          currentKeep = keys.reduce((acc, k) => {
            const selectedProperty = get(child, k) || "";

            return (
              acc ||
              filterFn({
                value: selectedProperty,
                filter: value,
              })
            );
          }, false);
        } else {
          const selectedProperty = get(
            child,
            filterGroup + ((key && "." + key) || "")
          );
          currentKeep = filterFn({
            value: selectedProperty,
            filter: value,
          });
        }

        // Apply each filter here based on type
        return keep && currentKeep;
      }, true);
      //.reduce((acc, item) => item + acc, 0);
    });

    return { ...currentGroup, children };
  }
);

export const calculateUnknownAlarmStates = (stats) => {
  const total = (stats && stats.countLiveSites) || 0;

  const alarmColours = get(stats, "alarmColours") || null;

  const alarmStatesRemainder =
    // If there are no alarm colours available, set to 0
    !alarmColours
      ? 0
      : Object.keys(alarmColours).reduce((acc, k) => {
          // Subtract each value from the total number
          // of sites and we will get a remainder (unknown states)
          return acc - (alarmColours[k] || 0);
        }, total);

  return alarmStatesRemainder;
};

/**
 * getGroupStats
 *
 * @description gets/builds proper stats object array
 * for statistics
 *
 * @param {*} group a group with stats property to build
 * a stats obj array from
 */
export const getGroupStats = (group) => {
  const { stats } = group;
  const total = (stats && stats.countLiveSites) || 0;

  const alarmStates = [
    {
      defaultState: true,
      color: "#C03A2B",
      icon: "burglary",
      // TODO: translate or get from backend
      name: "In Alarm",
      value: get(stats, "alarmColours.failRed") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "failRed",
    },
    {
      color: "#E67D22",
      icon: "burglary",
      name: "Alert",
      value: get(stats, "alarmColours.alertOrange") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "alertOrange",
    },
    {
      color: "#27AE5F",
      icon: "burglary",
      name: "Restore",
      value: get(stats, "alarmColours.restoreGreen") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "restoreGreen",
    },
    {
      color: "#3497DB",
      icon: "burglary",
      // Note: was "Unset" see #234
      name: "Open",
      value: get(stats, "alarmColours.unsetLightblue") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "unsetLightblue",
    },
    {
      color: "#29498B",
      icon: "burglary",
      name: "Close",
      value: get(stats, "alarmColours.setBlue") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "setBlue",
    },
    {
      color: "#2C3E4F",
      icon: "burglary",
      name: "Unknown",
      value: get(stats, "alarmColours.unknown") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "unknown",
    },
    {
      color: "#707070",
      icon: "burglary",
      name: "System",
      value: get(stats, "alarmColours.testGrey") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "testGrey",
    },
    {
      color: "#8E44AD",
      icon: "burglary",
      name: "Info",
      value: get(stats, "alarmColours.infoPurple") || 0,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.alarmColours",
      key: "infoPurple",
    },
  ];

  const closeValue = get(stats, "openClosed.locked") || 0;
  const openValue = get(stats, "openClosed.unlocked") || 0;

  const openClose = [
    {
      // Show as representative of this group
      // by default unless value is zero.
      // Override to be true if there are no sites
      // (i.e. loading state)
      defaultState: closeValue > 0 || total === 0,
      color: "#29498B",
      name: "Close",
      value: closeValue,
      tooltip: true,
      filterGroup: "stats.openClosed",
      icon: "lock",
      key: "locked",
    },
    {
      // Show as representative of this group
      // by if close is zero and this is greater than zero
      defaultState: closeValue === 0 && openValue > 0,
      color: "#3497DB",
      name: "Open",
      value: openValue,
      tooltip: true,
      // Key for filtering children
      filterGroup: "stats.openClosed",
      icon: "unlock",
      key: "unlocked",
    },
    {
      // Show as representative of this group
      // by if close and open are zero
      defaultState: closeValue === 0 && openValue === 0,
      color: "#2C3E4F",
      name: "24 Hour",
      value: get(stats, "openClosed.unknown") || 0,
      tooltip: true,
      filterGroup: "stats.openClosed",
      icon: "unknown",
      key: "unknown",
    },
  ];

  const highActivity = [
    {
      defaultState: true,
      color: "#F1C411",
      name: "High Activity",
      value: get(stats, "countHighAlarms") || 0,
      tooltip: false,
      filterGroup: "stats.countHighAlarms",
      icon: "bell",
      key: "",
    },
  ];

  const siteStats = [
    {
      color: "#F1C40F",
      icon: "bell",
      name: "High Activity",
      progressBars: highActivity,
      value: get(stats, "countHighAlarms") || 0,
    },
    {
      color: "#29498B",
      icon: "lock",
      name: "Site Status",
      progressBars: openClose,
      value: get(stats, "openClosed.locked") || 0,
    },
    {
      color: "#C03A2B",
      icon: "warning",
      name: "Latest Event",
      progressBars: alarmStates,
      value: get(stats, "alarmColours.failRed") || 0,
    },
  ];

  return {
    title: "Sites",
    stats: siteStats,
    icon: "site",
    total,
    showTotal: true,
  };
  // },
  // {
  //   title: "Current Alarm States",
  //   stats: alarmStates,
  //   total
  // },
  // {
  //   title: "Open/Close",
  //   stats: openClose,
  //   total
  // }
};
export const getCustomRef = ({ custom_group_ref, custom_ref, name }) =>
  custom_group_ref ||
  custom_ref ||
  ( // No custom-ref set, generate from site name
    name
      ? name
        .split(" ")
        .map((word) => word[0])
        .join("")
        // Remove non alphanumeric
        .replace(/\W/g, "")
        // Limit to 3 characters
        .slice(0, 3)
      : ""
  );

/**
 * Returns group tree with logged in user's user management access
 */
export const getGroupsWithAccess = createSelector(
  [(state) => state.user, getGroups, getEnterpriseFromRoute],
  (user, groups, enterprise) => {
    if (user.access.loading !== false || groups.length === 0) return null;

    // Build "fake" root group
    const groupsWithRootGroup = [
      {
        name: enterprise.name,
        root: true,
        id: enterprise.root_group_id,
        // children: groups.map(g => {
        //   return { ...g, role: "Admin" };
        // }),
        children: groups,
        // Displays muted text on the group item
        role: user.access.enterprise.role_name,
        role_id: user.access.enterprise.role_id,
      },
    ];

    let unfoundGroup = false;
    // Access is inherited from parents
    const inheritAccess = (group, parentAccess) => {
      let access = parentAccess;
      // No parent access provided, attempt to find access for this group
      if (!access)
        access = user.access.groups.find(({ id }) => id === group.id);

      // let group = getGroupById(groupsWithRootGroup, access.id);

      // No access found for this group either
      if (!access) {
        if (
          devMode() &&
          // Root group is not always returned from backend
          !group.root
        ) {
          console.warn(
            "Inconsistency between user access groups and cached group tree. Unfound group:",
            group,
            groupsWithRootGroup
          );
        }
        unfoundGroup = true;
      }

      // Get children to inherit access if they exist
      if (group.children) {
        return {
          ...group,
          // Add access
          role_id: (access || {}).role_id || null,
          role_name: (access || {}).role_name || null,
          features: (access || {}).features || [],
          children: group.children.map((child) => {
            return inheritAccess(child, access);
          }),
        };
      }

      return {
        ...group,
        // Add access
        role_id: (access || {}).role_id || null,
        role_name: (access || {}).role_name || null,
        features: (access || {}).features || [],
      };
    };
    const accessGroups = groupsWithRootGroup.map((group) =>
      inheritAccess(group)
    );

    return {
      groups: accessGroups,
      unfoundGroup,
    };
  }
);
