export const loadCompaniesStart = (params) => {
  return { type: "LOAD_COMPANIES_START", ...params };
};

export const loadCompaniesSuccess = (params) => {
  return { type: "LOAD_COMPANIES_SUCCESS", ...params, };
};

export const loadCompaniesFailed = (params) => {
  return { type: "LOAD_COMPANIES_FAILED", ...params };
};

export const loadCompaniesCancel = (params) => {
  return { type: "LOAD_COMPANIES_CANCEL", ...params };
};

export const clearCompanies = (params) => {
  return { type: "CLEAR_COMPANIES", ...params };
};