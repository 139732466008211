import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { loadPinMapsStart } from 'services/redux/actions/serviceDesk';
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";

import Alert from "components/Common/Alert";
import FormElement from './FormElement';
import Icon from "components/Icons/Icon";
import { Tooltip } from "components/Common/Tooltip/Tooltip";

import conxtdOut from "apis/conxtdOut";

import "./AddPin.scss";
import SaveSiteSuccess from '../../AddSingleSite/SaveSiteSuccess';

const AddPin = ({ sudoSiteId, siteName, loadPinMapsStart, pinType, mappedData, enterprise_id, smallScreen }) => {
  const dispatch = useDispatch();

  const newFormElement = {
    id: uuidv4(),
    null: true,
    action: "add",
    sudo_site_id: sudoSiteId,
    pin: "",
    event_pair_id: "",
    customText: "",
    reverse: "N",
  }

  const [disabledButton, setDisabledButton] = useState("disabled");
  const [formElements, setFormElements] = useState([newFormElement]);
  const [newPinType, setNewPinType] = useState("");
  const [showModalInitialise, setShowModalInitialise] = useState(false);
  const [showModalMain, setShowModalMain] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (smallScreen) {
      setFormElements([newFormElement]);
    }
  }, [smallScreen, setFormElements])

  useEffect(() => {
    if (formElements.length > 1) {
      setDisabledButton("");
    } else {
      setDisabledButton("disabled");
    }
  }, [formElements])

  const handleClose = () => {
    setShowModalInitialise(false);
    setShowModalMain(false);
  };
  const handleShow = () => {
      pinType ? setShowModalMain(true) : setShowModalInitialise(true);
  };  

  const validateForNull = (initialising) => {
    let fieldNotNull = true
    fieldNotNull = formElements.find(element => element.null === false)
    if (fieldNotNull) {
      Alert({
        text: `Close this window? Any unsaved changes will be lost.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Close",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value) {
          if (initialising === "init") {
            setShowModalMain(false)
            setShowModalInitialise(true)
            setFormElements([newFormElement])
            return
          }
          handleClose()
          setFormElements([newFormElement])
        } else {
          return
        }
      })
    } else {
      if (initialising === "init") {
        setShowModalMain(false)
        setShowModalInitialise(true)
      } else {
        handleClose()
        setFormElements([newFormElement])
      }
    }
  };

  // handle submit function for form
  let nullFieldFound = formElements.find(element => element.null === true);
  const handleSubmit = () => {
    if (!(nullFieldFound) && !(invalid)) {
      Alert({
        html: "<div><p>Are you sure you want to save these new changes?</p><hr /><p>Note: Changes to pins may have unexpected results with third-party alarm receiving centres.</p></div>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value) {

          try {
            const cleanupPins = () => {
              const newArray = [];
              [...formElements].forEach(element => {
                element = {
                  sudo_site_id: sudoSiteId,
                  [pinType === "SIA" || newPinType === "SIA" ? "pin" : "channel"]: pinType === "SIA" || newPinType === "SIA" ? element.pin : element.pin - 1,
                  event_pair_id: element.event_pair_id,
                  custom_text: element.customText,
                  reverse: element.reverse === true ? "Y" : "N",
                }
                newArray.push(element);
              });
              return newArray;
            }

            if (sudoSiteId && formElements) {
              
              const recordsToAdd = formElements.filter(element => element.action === "add");

              // submit added pins to backend
              if (recordsToAdd.length > 0) {
                
                const addPins = conxtdOut.post(`/SudoPins/add/${sudoSiteId}/${pinType || newPinType}?enterpriseId=${enterprise_id ?? null}`, JSON.stringify({
                  pin_data: cleanupPins(),
                  added_total: recordsToAdd.length,
                  site_name: siteName,
                }));
  
                addPins.then((response) => {
                  if (response.status === 200) {
                    {smallScreen ?
                      setShowAlert(true)
                    :
                      Alert({
                        text: `${formElements.length} pin${formElements.length < 2 ? "" : "s"} successfully added.`,
                        icon: "success",
                        timerProgressBar: true,
                        timer: 5000,
                      });
                    }
                    if (sudoSiteId) {
                      dispatch(
                        loadPinMapsStart({
                          sudo_site_id: sudoSiteId,
                        })
                      );
                    }
                    handleClose();
                    setFormElements([newFormElement]);
                  };
                }).catch((error) => {
                  console.log(error.message);
                  setShowAlert(false);
                  Alert({
                    text: "Add pins failed, please try again.",
                    icon: "error",
                    timerProgressBar: true,
                    timer: 5000,
                  });
                });
              }
            };

          } catch (error) {
            console.log(error);
          } 
          
        } else {
          return
        }
      });
    } else if (invalid) {
      Alert({
        text: `Invalid entries detected. Please ensure all 'Pin' and 'Alarm' input fields have valid values.`,
        icon: "warning",
      });
    } else {
      const elementsWithNull = formElements.filter(element => element.null === true).length;
      Alert({
        text: `${elementsWithNull} pin${elementsWithNull > 1 ? 's' : ""} with null values detected. Please ensure all 'Pin' and 'Alarm' input fields have valid entries.`,
        icon: "warning",
      });
    }
  };

  const deleteFormElement = (idToDelete) => {
    setFormElements(formElements.filter((element) => {
      return element.id !== idToDelete
    }));
  };

  const addFormField = () => {
    setFormElements(prevState => [...prevState, newFormElement]);
  };

  return (
    <>
      <Tooltip
        description="Add Pins"
      >
        <Button variant="primary" onClick={handleShow}>
          <Icon
            className="fas fa-plus"
          />
        </Button>
      </Tooltip>
        <div>
          {/* Modal to initialize pin type */}
          <Modal
            show={showModalInitialise}
            onHide={handleClose}
            id="modal-init"
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Pin Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='container' id="modal-body-init">
                <span className='pin-type-text'>Please select which pin type you would like to add.</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                data-intercom-target={"sia-pin-type-button"}
                variant="secondary" 
                onClick={() => {
                  setNewPinType("SIA")
                  handleClose()
                  setShowModalMain(true)
                }}
              >
                SIA
              </Button>
              <Button
                data-intercom-target={"sia-pin-type-button"}
                variant="secondary" 
                onClick={() => {
                  setNewPinType("Fast Format")
                  handleClose()
                  setShowModalMain(true)
                }}
              >
                Fast Format
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Main add pin modal */}
          <div>
              <Modal
                show={showModalMain}
                onHide={validateForNull}
                id="modal-main"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{`Add Pin${!smallScreen ? 's' : ''}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="col" id="modal-body-add" data-intercom-target={"add-pins-body"}>
                  <div
                    className='container' 
                    id='modal-body-container'
                  >
                    <div
                      className='container'
                      id={!smallScreen && `form-label`}
                    >
                      {!smallScreen && <div className='row flex-nowrap'>
                        <Form.Label className="col-2 label-title justify-content-start">Pin</Form.Label>
                        <Form.Label className="col-3 label-title justify-content-start">Alarm</Form.Label>
                        <Form.Label className="col-xl-5 col-lg-5 col-md-4 col-sm-4 label-title justify-content-start">Custom Text</Form.Label>
                          <div className='container m-0' id='outer-wrapper'>
                            <div className="d-flex justify-content-between m-0 p-0" id="outer-wrapper-child">
                              <Form.Label className="text-center child-content-element">Reverse</Form.Label>
                              <Form.Label className="text-center" id="label-border"></Form.Label>
                              <Form.Label className="text-center child-content-element">Delete</Form.Label>
                            </div>
                          </div>
                      </div>}
                    </div>
                    <Form noValidate onSubmit={handleSubmit}>
                      <ul className='container'>
                        <li className='row'>
                            {
                            formElements.map((element) => {
                              return <FormElement
                                key={element.id}
                                id={element.id}
                                action={element.action}
                                deleteFormElement={deleteFormElement}
                                formElements={formElements}
                                setFormElements={setFormElements}
                                disabledButton={disabledButton}
                                invalid={invalid}
                                nullFieldFound={nullFieldFound}
                                setInvalid={setInvalid}
                                mappedData={mappedData}
                                smallScreen={smallScreen}
                              />
                            })
                          }
                        </li>
                      </ul>
                    {!smallScreen &&
                      <Tooltip
                        description="Add another pin"
                        placement="bottom"
                      >
                        <Button
                          className="col"
                          onClick={addFormField}
                        >
                          <Icon
                            className="fas fa-plus"
                          />
                        </Button>
                      </Tooltip>
                    }
                  </Form>
                  </div>
                </Modal.Body>
                <Modal.Footer 
                  className={`${pinType ? "" : "d-flex justify-content-between"}`}
                >
                  {pinType
                  ?
                    <></>
                  :
                    <div className='d-flex align-items-center flex-row'>
                      <Button variant="secondary" data-intercom-target={"back-add-pin-button"} onClick={() => 
                      {
                        validateForNull("init")
                      }}>
                        Back
                      </Button>
                      <div className='ml-3'>
                        <span>Selected: </span>
                        <span id='pin-type-label'>{newPinType}</span>
                      </div>
                    </div>
                  }
                  <div
                    className='d-flex justify-content-end'
                  >
                    <Button
                      data-intercom-target={"close-add-pins-button"}
                      variant="secondary" 
                      onClick={validateForNull}
                    >
                      Close
                    </Button>
                    {invalid || nullFieldFound
                      ?
                        <Tooltip
                          description="Invalid or null entries detected"
                          placement='top'
                        >
                          <Button
                            data-intercom-target={"save-changes-add-pins-button"}
                            variant="primary"
                            className="ml-2 disabled"
                            type="submit"
                            onClick={() => {
                            }}
                          >
                            Save Changes
                          </Button>
                        </Tooltip>
                      :
                        <Button
                        data-intercom-target={"save-changes-add-pins-button"}
                          variant="primary"
                          className="ml-2"
                          type="submit"
                          onClick={() => {
                            handleSubmit()
                          }}
                        >
                          Save Changes
                        </Button>
                    }
                  </div>
                </Modal.Footer>
              </Modal>
          </div>
        </div>
  
        <SaveSiteSuccess
          handleAddShow={handleShow}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          inputTextMain={'Pin added successfully. Add another Pin?'}
          inputTextButton={'Add Pin'}
        />

    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    loadPinMapsStart,
    enterprise: getEnterpriseFromRoute(state, props),
  };
};

export default connect(mapStateToProps, null)(AddPin);