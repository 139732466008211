/**
 * Uptick utility functions
*/

export const getAlarmDisplayText = (alarmText) => {
  let alarmDisplayText = alarmText;

  if (alarmDisplayText) {
    // If this an alarm created from Uptick, the alarm text will contain a number of dated actions.
    // The backend will include uri-style encoding for line breaks before the date/time of each action. Nothing else is encoded.
    // We need to make sure the linebreaks display correctly.
    // First, find the uri-style new line encoding (%0A) followed by the date/time in the format 'DD/MM/YYY, hh:mm :'
    try {
      alarmDisplayText = alarmDisplayText.replaceAll(/(%0A)(\d\d\/\d\d\/\d\d\d\d, \d\d:\d\d :)/g, (match, p1, p2) => {
        // Then, replace the uri-style new line encoding with the javascript new line encoding, and put the date/time back exactly as-is
        return "\n" + p2; // p2 is the matched string found by the part of the regex in the second set of parenthesis, i.e. the formatted date/time in this case
      });
    } catch (ignore) {};
  }

  // Note: if this isn't an alarm created from Uptick, then the text should be returned unchanged
  return alarmDisplayText;
}