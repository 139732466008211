import React from "react";
import { connect } from "react-redux";

import { Card, Col, Row } from "react-bootstrap";

import Icon from "components/Icons/Icon";
import CircleIcon from "components/Icons/CircleIcon";

const suggestionCards = [
  {
    cardId: 0,
    title: "Fire Test",
    colSize: 4,
    color: "#c0392b",
    conxtdIcon: "fire",
    bundleSuggestionsMars: [],
    bundleSuggestionsStaging: [],
    bundleSuggestionsLive: [],
    eventSuggestionsMars: [376, 377, 378, 380],
    eventSuggestionsStaging: [376, 377, 378, 380],
    eventSuggestionsLive: [396, 397, 398, 400],
  },
  {
    cardId: 1,
    title: "Open/Close",
    colSize: 4,
    color: "#3498db",
    conxtdIcon: "unlock",
    bundleSuggestionsMars: [1],
    bundleSuggestionsStaging: [1],
    bundleSuggestionsLive: [1],
    eventSuggestionsMars: [],
    eventSuggestionsStaging: [],
    eventSuggestionsLive: [],
  },
  {
    cardId: 2,
    title: "Comments",
    colSize: 4,
    color: "#8e44ad",
    conxtdIcon: "comment",
    bundleSuggestionsMars: [],
    bundleSuggestionsStaging: [],
    bundleSuggestionsLive: [],
    eventSuggestionsMars: [362],
    eventSuggestionsStaging: [362],
    eventSuggestionsLive: [408],
  },
  {
    cardId: 3,
    title: "Faults",
    colSize: 4,
    color: "#e67e22",
    conxtdIcon: "spanner",
    bundleSuggestionsMars: [25],
    bundleSuggestionsStaging: [25],
    bundleSuggestionsLive: [25],
    eventSuggestionsMars: [],
    eventSuggestionsStaging: [],
    eventSuggestionsLive: [],
  },
  {
    cardId: 4,
    title: "Engineer",
    colSize: 4,
    color: "#777777",
    conxtdIcon: "engineer",
    bundleSuggestionsMars: [19],
    bundleSuggestionsStaging: [19],
    bundleSuggestionsLive: [19],
    eventSuggestionsMars: [],
    eventSuggestionsStaging: [],
    eventSuggestionsLive: [],
  },
  {
    cardId: 5,
    title: "Communication Events",
    colSize: 4,
    color: "#e67e22",
    iconClassName: "fas fa-wifi",
    bundleSuggestionsMars: [18],
    bundleSuggestionsStaging: [18],
    bundleSuggestionsLive: [18],
    eventSuggestionsMars: [],
    eventSuggestionsStaging: [],
    eventSuggestionsLive: [],
  }
];

function BundleCard({
  card,
  environment,
  applySuggestions,
  selected,
}) {
  const icon = card.conxtdIcon ? (
    <CircleIcon
      type={card.conxtdIcon}
      foregroundColor={card.color}
      size="60"
    />
  ) : (
    <Icon
      className={card.iconClassName}
      style={{ fontSize: "30px", height: "45px", color: card.color }}
    />
  );

  return (
    <Col md={card.colSize}>
      <Card
        onClick={() => {
          if (environment === "mars" || environment === "devbackend") {
            applySuggestions(card.cardId, card.bundleSuggestionsMars, card.eventSuggestionsMars);
          } else if (environment === "staging") {
            applySuggestions(card.cardId, card.bundleSuggestionsStaging, card.eventSuggestionsStaging);
          } else {
            applySuggestions(card.cardId, card.bundleSuggestionsLive, card.eventSuggestionsLive);
          }
        }}
        className={`channel-card toggle-card bg-light d-flex ${
          selected ? "selected" : ""
        }`}
        style={{
          cursor: "pointer",
          minHeight: "111px"
        }}
      >
        <div className="my-auto px-3">
          <Row>
            <Col md="12" className="text-center">
              {icon}
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <Card.Subtitle style={{ fontSize: "14px" }}>
                {card.title}
              </Card.Subtitle>
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
}

class Suggestions extends React.Component {

  render() {
    const { environment, applySuggestions, selectedSuggestionsCardId } = this.props;

    const suggestions = suggestionCards.map((card, index) => (
      <BundleCard
        key={index}
        card={card}
        environment={environment}
        applySuggestions={applySuggestions}
        selected={selectedSuggestionsCardId === card.cardId}
      />
    ));

    return (
      <>
        <Row>
          <Col className="header-title">Suggestions</Col>
        </Row>
        <Row>{suggestions}</Row>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    environment: state.user.environment
  };
};
export default connect(mapStateToProps)(Suggestions);