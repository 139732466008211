import React, { Component } from "react";

import { Button, Row, Col, Form, FormGroup, Nav, Tab } from "react-bootstrap";
import { connect } from "react-redux";

// selectors
import { getEnterpriseFromRoute } from "services/redux/selectors/enterprises";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";
import { getGroupFromRoute } from "services/redux/selectors/groups";
import { getCurrentGroupFiltered } from "services/redux/selectors/groups";
import countryList from "react-select-country-list";

import _ from "lodash";
import conxtdOut from "apis/conxtdOut";
import Alert from "components/Common/Alert";
import ErrorBadge from "components/Layout/ErrorBadge";
import PinMapping from "components/Companies/Company/AssetEditor/PinMapping/PinMapping";

import { loadEmSitesListStart } from "services/redux/actions/enterpriseManager";
import Loading from "components/Loading";

import './ViewEditSite.scss';

class ViewEditSite extends Component {
  constructor(props) {
    super(props);

    this.options = countryList().getData();

    this.state = {
      enterprise_user_field: {},
      nestedSiteFields: [],
      options: this.options,
      valueTest: null,
      site_ref: null,
      transmitter_ref: null,
      // custom_fields: [],
      showModal: false,
      pinsLoaded: false,
    };
  }

  /* handle submit for for details bundle */
  handleSubmit = async (event) => {
    event.preventDefault();
    const { siteDetail } = this.props;

    const enterprise_site_id = siteDetail.id;
    const address1 = event.target.address1.value;
    const address2 = event.target.address2.value;
    const company = event.target.company.value;

    // let country_code = null;
    // if (this.state.valueTest === null) {
    //   country_code = this.props.country_code;
    // } else {
    //   country_code = this.state.valueTest.value;
    // }

    try {
      await conxtdOut.post(`/EnterpriseSites/edit/${enterprise_site_id}`, {
        sudo_site: {
          address1,
          address2,
          company,
          site_ref: this.props.site_ref,
          transmitter_ref: this.props.transmitter_ref,
          town: event.target.town.value,
          postcode: event.target.postcode.value,
          country_code: event.target.country_code.value,
        },
        custom_site_id: event.target.custom_ref.value,
        name: event.target.name.value,
      });

      this.props.dispatch(
        loadEmSitesListStart({
          enterprise_group_id: this.props.group_id,
        })
      );
      Alert({
        text: "Site has been edited.",
        icon: "success",
        timerProgressBar: true,
        timer: 1000,
      });
      // wait 10 secs before closing side pane
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // this.props.onHide();
    } catch (error) {
      console.log("Error details", error);
    }
  };

  /* handle submit for for custom fields bundle */
  handleSubmit2 = async (event) => {
    event.preventDefault();
    const { siteDetail } = this.props;
    const enterprise_site_id = siteDetail.id;

    let custom_fields = _.cloneDeep(this.props.siteDetail.enterprise_user_fields);

    custom_fields.forEach((item) => {
      if (!event.target[item.name].value) {
        // if (item.site_fields[0] && item.site_fields[0].value) { 
        //   delete item.site_fields[0].value;
        // }
      } else {
        item.site_fields[0] = { ...item.site_fields[0], value: event.target[item.name].value };
      }
    });
    try {
      await conxtdOut.post(
        `/EnterpriseSites/editSiteFields/${enterprise_site_id}`,
        // this.state.custom_fields
        custom_fields
      );
      // this.setState({
      //   custom_fields: [],
      // });
      this.props.dispatch(
        loadEmSitesListStart({
          enterprise_group_id: this.props.group_id,
        })
      );

      Alert({
        text: "Site has been edited.",
        icon: "success",
        timerProgressBar: true,
        timer: 1500,
      });
      // wait 10 secs before closing side pane
      //   await new Promise((resolve) => setTimeout(resolve, 1000));
      // this.props.onHide();
    } catch (error) {
      console.log("Error details", error);
    }
  };

  /* handle submit for for setup bundle */
  handleSubmit3 = async (event) => {
    event.preventDefault();
    const { siteDetail } = this.props;
    const enterprise_site_id = siteDetail.id;

    try {
      await conxtdOut.post(`/EnterpriseSites/edit/${enterprise_site_id}`, {
        sudo_site: {
          address1: this.props.address1,
          address2: this.props.address2,
          company: this.props.company,
          site_ref: this.props.site_ref,
          transmitter_ref: event.target.transmitter_ref.value,
          town: this.props.town,
          postcode: this.props.postcode,
          country_code: this.props.country_code,
        },
        custom_site_id: this.props.custom_ref,
        name: this.props.name,
      });
      this.props.dispatch(
        loadEmSitesListStart({
          enterprise_group_id: this.props.group_id,
        })
      );

      Alert({
        text: "Site has been edited.",
        icon: "success",
        timerProgressBar: true,
        timer: 1000,
      });
      // wait 10 secs before closing side pane
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // this.props.onHide();
    } catch (error) {
      console.log("Error details", error);
    }
  };

  // // custom fields change handler
  // handleChange = (event, index) => {
  //   event.preventDefault();
  //   const value = event.target.value;

  //   const site_fields = this.props.siteDetail.enterprise_user_fields;

  //   let custom_fields = _.cloneDeep(site_fields);

  //   if (!custom_fields === null) {
  //     custom_fields[index].site_fields[0].value = { value: value };
  //   } else custom_fields[index].site_fields[0] = { value: value };
  //   console.log(custom_fields);
  //   this.setState({ custom_fields });
  // };

  // country list change handler
  changeHandler = (valueTest) => {
    this.setState({ valueTest });
  };

  render() {

    const handlePinsLoaded = () => {
      if (!this.state.pinsLoaded) {
        this.setState({ pinsLoaded: true });
      }
    }

    const lableStyle = { display: "flex", alignItems: "flexStart" };

    return (
      <>
        <div className="container">
          <Tab.Container defaultActiveKey="details">
            <Nav style={{ marginTop: "20px" }} variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="details">Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="customFields">Custom Fields</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="setup">Setup</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pinMapping">Pin Mapping</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content style={{ marginTop: "20px" }}>
              <Tab.Pane eventKey="details">
                <Row>
                  <Col sm={7}>
                    <Form
                      onSubmit={this.handleSubmit}
                      show={this.props.show}
                      key={this.props.name}
                    >
                      <Form.Group style={{ width: "440px" }}>
                        <FormGroup style={{ width: "450px", marginTop: "5px" }}>
                          <Form.Label style={lableStyle}> Site Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            className="mb-3"
                            required
                            defaultValue={this.props.name}
                          />
                        </FormGroup>
                        <FormGroup style={{ width: "450px" }}>
                          <Form.Label style={lableStyle}>
                            Custom Site Ref
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="custom_ref"
                            className="mb-3"
                            defaultValue={this.props.custom_ref}
                          />
                        </FormGroup>
                        <Form.Text
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Address
                          <hr
                            className="borderBreak"
                            style={{ border: "1px solid black" }}
                          ></hr>
                        </Form.Text>
                        <FormGroup style={{ width: "450px" }}>
                          <Form.Label style={lableStyle}>Company</Form.Label>
                          <Form.Control
                            type="text"
                            name="company"
                            className="mb-3"
                            defaultValue={this.props.company}
                          />
                        </FormGroup>

                        <FormGroup style={{ width: "450px" }}>
                          <Form.Label style={lableStyle}>
                            Address Line 1
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="address1"
                            className="mb-3"
                            defaultValue={this.props.address1}
                          />
                        </FormGroup>
                        <FormGroup style={{ width: "450px" }}>
                          <Form.Label style={lableStyle}>
                            Address Line 2
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="address2"
                            className="mb-3"
                            defaultValue={this.props.address2}
                          />
                        </FormGroup>
                        <FormGroup style={{ width: "450px" }}>
                          <Form.Label style={lableStyle}>Town</Form.Label>
                          <Form.Control
                            type="text"
                            name="town"
                            defaultValue={this.props.town}
                          />
                        </FormGroup>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <FormGroup
                            style={{ width: "100px", marginRight: "50px" }}
                          >
                            <Form.Label style={lableStyle}>
                              Postcode / ZIP code
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="postcode"
                              defaultValue={this.props.postcode}
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "450px" }}>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                              as="select"
                              name="country_code"
                              className="mb-3"
                              defaultValue={this.props.country_code}
                            >
                              {" "}
                              {this.state.options.map((obj) => {
                                return (
                                  <option key={obj.value} value={obj.value}>
                                    {obj.label}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </FormGroup>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flexStart",
                          }}
                        >
                          <Button
                            variant="primary"
                            className="mr-2"
                            onClick={this.props.onHide}
                          >
                            Close
                          </Button>
                          <Button variant="success" type="submit">
                            Update
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>{" "}
              </Tab.Pane>
              {/** Custom fields **/}
              <Tab.Pane eventKey="customFields">
                <Row type="flex" justify="space-around">
                  <Col span={10}>
                    <Form
                      onSubmit={this.handleSubmit2}
                      show={this.props.show}
                    >
                      {this.props.loading ||
                      this.props.loadingViewGroup === true ||
                      this.props.siteDetail === null ||
                      this.props.siteDetailCheck.length === 0 ? (
                        <Loading />
                      ) : this.props.siteDetail?.enterprise_user_fields === null ||
                        this.props.siteDetail?.enterprise_user_fields.length ===
                          0 ? (
                        <ErrorBadge
                          message={"There are no custom fields for this site."}
                        />
                      ) : (
                        // logic for empty custom fields
                        this.props.siteDetail?.enterprise_user_fields.map(
                          (details, index) => {
                            const site_fields = details.site_fields[0];
                            return details.site_fields.length === 0 ? (
                              <div key={details.id}>
                                {/* <Form
                                  onSubmit={this.handleSubmit2}
                                  show={this.props.show}
                                > */}
                                  <Form.Group>
                                    <Form.Group style={{ width: "350px" }}>
                                      <Form.Label style={lableStyle}>
                                        {details.name}
                                      </Form.Label>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flexStart",
                                        }}
                                      >
                                        <Form.Control
                                          type="text"
                                          name={details.name}
                                          // onChange={(event) =>
                                          //   this.handleChange(event, index)
                                          // }
                                        />

                                        {/* <Button
                                          variant="success"
                                          className="ml-2 pt-1"
                                          type="submit"
                                          onClick={this.handleSubmit2}
                                        >
                                          Update
                                        </Button> */}
                                      </div>
                                    </Form.Group>
                                  </Form.Group>
                                {/* </Form> */}
                              </div>
                            ) : (
                              // logic for populated custom fields
                              <div key={site_fields.value}>
                                {/* <Form
                                  onSubmit={this.handleSubmit2}
                                  show={this.props.show}
                                > */}
                                  <Form.Group>
                                    <Form.Group style={{ width: "350px" }}>
                                      <Form.Label style={lableStyle}>
                                        {details.name}
                                      </Form.Label>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flexStart",
                                        }}
                                      >
                                        <Form.Control
                                          type="text"
                                          name={details.name}
                                          defaultValue={site_fields.value}
                                          // onChange={(event) =>
                                          //   this.handleChange(event, index)
                                          // }
                                        />

                                        {/* <Button
                                          variant="success"
                                          className="ml-2 pt-1"
                                          type="submit"
                                          onClick={this.handleSubmit2}
                                        >
                                          Update
                                        </Button> */}
                                      </div>
                                    </Form.Group>
                                  </Form.Group>
                                {/* </Form> */}
                              </div>
                            );
                          }
                        )
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flexStart",
                        }}
                      >
                        <Button
                          variant="primary"
                          className="mr-2"
                          onClick={this.props.onHide}
                        >
                          Close
                        </Button>
                        <Button 
                          variant="success"
                          type="submit"
                        >
                          Update
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>{" "}
              </Tab.Pane>

              {/** Setup **/}
              <Tab.Pane eventKey="setup">
                <Row>
                  <Col sm={7}>
                    <Form
                      onSubmit={this.handleSubmit3}
                      show={this.props.show}
                      key={this.props.name}
                    >
                      <Form.Group>
                        <FormGroup style={{ width: "100px", marginTop: "5px" }}>
                          <Form.Label style={lableStyle}>Site Ref</Form.Label>
                          <Form.Control
                            type="text"
                            name="site_ref"
                            className="mb-3"
                            defaultValue={this.props.site_ref}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup style={{ width: "100px" }}>
                          <Form.Label style={lableStyle}>
                            Transmitter Id{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="transmitter_ref"
                            className="mb-3"
                            defaultValue={this.props.transmitter_ref}
                          />
                        </FormGroup>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "flexStart",
                            marginTop: "40px",
                          }}
                        >
                          <Button
                            variant="primary"
                            className="mr-2"
                            onClick={this.props.onHide}
                          >
                            Close
                          </Button>
                          <Button variant="success" type="submit">
                            Update
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="pinMapping">
                {this.props.sudo_site && this.props.sudo_site.id &&
                  <PinMapping enterprise_id={this.props.enterprise.id} sudoSite_id={this.props.sudo_site.id} siteName={this.props.siteDetail?.siteName} setPinsLoaded={handlePinsLoaded} />
                }
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    enterprise: getEnterpriseFromRoute(state, props),
    group: getGroupFromRoute(state, props),
    sites: state.sites.all.data,
    currentGroup: getCurrentGroupFiltered(state),
    enterpriseManager: state.enterpriseManager.items.enterprise_sites,
    loading: state.enterpriseManager.loadingSiteDetail,
    siteDetailCheck: state.enterpriseManager.siteDetail,
    siteDetail: state.enterpriseManager.siteDetail.enterprise_site,
    getEventMetaById: createGetEventMetaById(state, props),
    loadingMetaData: state.eventMeta.loading,
    loadingPinMapsData: state.enterpriseManager.loading,
    pinMapsData: state.enterpriseManager.pinMaps,
    darkMode: state.app.darkMode,
  };
};

export default connect(mapStateToProps, null)(ViewEditSite);
