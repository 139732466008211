import {
  getRepresentativeOccurrence
} from "services/redux/selectors/reports/scheduled";

export function ZoneColumn({ columns, eventTextFilter, getEventMeta }) {
  const column = columns["ZONE"];
  return {
    ...column,

    accessor: site => {
      const occurrence = getRepresentativeOccurrence({
        occurrences: site.occurrences,
        eventTextFilter,
        getEventMeta
      });

      return occurrence.zone;
    }
  };
}