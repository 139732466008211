const initialState = {
  data : {
    companies: [],
    pagination: {
      count: 0,
      pageCount: 1,
    },
  },
  pageSize: 20,
  nameFilter: null,
  sortField: null,
  sortDirection: "desc",
  loadingCompanies: null,
};

export default function companies(state = initialState, action) {
  switch (action.type) {

    case "LOAD_COMPANIES_START":
      return {
        ...state,
        loadingCompanies: true,
      };

    case "LOAD_COMPANIES_SUCCESS":
      return {
        ...state,
        data: action.data ? action.data : {
          companies: [],
          pagination: {
            count: 0,
            pageCount: 1,
          },
        },
        displayPageIndex: action.displayPageIndex || state.displayPageIndex,
        pageSize: action.startAction.pageSize || 20,
        // nameFilter: action.startAction.nameFilter || null,
        sortField: action.startAction.sortField || "name",
        sortDirection: action.startAction.sortDirection || "desc",
        loadingCompanies: false,
      }

    case "LOAD_COMPANIES_FAILED":
      return {
        ...state,
        loadingCompanies: false,
      };

    case "LOAD_COMPANIES_CANCEL":
      return {
        ...state,
        loadingCompanies: false,
      };
    
    case "CLEAR_COMPANIES":
      return {
        ...state,
        data : {
          companies: [],
          pagination: {
            count: 0,
            pageCount: 1,
          },
        },
        displayPageIndex: 0,
        pageSize: 20,
        sortMapField: null,
        sortDirection: "desc",
        loadingCompanies: null,
      };

    default:
      return state;
  }
}