/**
 * User management role IDs. These are consistent across
 * mars/devbackend, staging and live
 */
const USER_ROLE_IDS = {
  ADMIN: 2,
  MANAGER: 3,
  MEMBER: 4,
  VIEWER: 5
};

export default USER_ROLE_IDS;