import React, { useState, useEffect } from 'react';
import { Row, Form, FormGroup, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Icon from 'components/Icons/Icon';
import { Tooltip } from 'components/Common/Tooltip/Tooltip';
import { darkModeSelectStyles } from "components/Common/CustomStyles/darkModeSelectStyles";
import './AddToEnterpriseElement.scss';
import {
  selectEnterpriseFields,
  selectEnterpriseGroups,
} from "services/redux/selectors/serviceDesk/addSite";
import { 
  loadEnterpriseFieldsStart,
  loadEnterpriseFieldsReset,
  loadEnterpriseGroupsStart,
  loadEnterpriseGroupsReset,
 } from "services/redux/actions/serviceDesk";
 import Loading from "components/Loading";

function AddToEnterpriseElement({
  // props
  accordionStyle,
  enterprise,
  setEnterprise,
  name,
  setName,
  custom_site_ref,
  setCustomSiteRef,
  billingStatus,
  setBillingStatus,
  enterprise_groups,
  setEnterpriseGroups,
  enterpriseCustomFieldsData,
  setEnterpriseCustomFieldsData,
  formBillingStatus,
  invalidEnterprise, 
  setInvalidEnterprise, 
  formEnterprises, 
  addToEnterprise,
  setAddToEnterprise,
  mapCustomFields,
  formEnterpriseGroups,
  setFormEnterpriseGroups,
  initialiseFormDataTrigger,
  loadingEnterpriseList = false,
 }) {

  const dispatch = useDispatch();
  // redux selectors ------
  const darkMode = useSelector((state) => state.app.darkMode)
  const { getEnterpriseFieldsData } = useSelector(selectEnterpriseFields);
  const { getEnterpriseGroupsData } = useSelector(selectEnterpriseGroups);
  // -------------------

  const [initInvalidComponent] = useState({
    enterprise_name: {null: true},
    enterprises: {null: true},
    billing_status: {null: true},
  });
  const [invalidEntComponent, setInvalidEntComponent] = useState(initInvalidComponent);
  const [enterpriseCustomFields, setEnterpriseCustomFields] = useState({});
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleAccordion = () => {
    setAddToEnterprise(!addToEnterprise);
  }

  const handleMouseEnter = () => {
    setIsHovered(false);
  }
  
  const handleMouseLeave = () => {
    setIsHovered(true);
  }

  useEffect(() => {
    addToEnterprise && Object.values(invalidEntComponent).some(value => value.null) ? setInvalidEnterprise(true) : setInvalidEnterprise(false);
  }, [invalidEnterprise, 
    invalidEntComponent, 
    addToEnterprise, 
    setInvalidEnterprise,
    name,
    enterprise,
    custom_site_ref,
    billingStatus,
    enterprise_groups,
    enterpriseCustomFieldsData,
  ])

  useEffect(() => {
    if (initialiseFormDataTrigger) {
      setInvalidEntComponent(initInvalidComponent)
    }
  }, [initialiseFormDataTrigger, initInvalidComponent, setInvalidEntComponent])

  const handleNullChange = (name, value) => {
    setInvalidEntComponent((prevState) => ({
      ...prevState,
      [name]: { null: value ? false : true},
    }));
  };

  useEffect(() => {
    if (addToEnterprise && enterprise) {
      dispatch(loadEnterpriseFieldsStart({ enterprise }))
    } else {
      setEnterpriseCustomFields({});
      setFormEnterpriseGroups([]);
    }
    if (enterprise) {
      dispatch(loadEnterpriseGroupsStart({ enterprise }))
    } else {
      setFormEnterpriseGroups([])
      setEnterpriseGroups(null)
    }

    if (!addToEnterprise) {
      setBillingStatus("");
      setEnterpriseCustomFields({});
      setEnterprise(null);
      setEnterpriseGroups(null);
      setFormEnterpriseGroups([]);
      dispatch(loadEnterpriseFieldsReset());
      dispatch(loadEnterpriseGroupsReset());
    }
  }, [addToEnterprise, enterprise, setEnterprise, setEnterpriseGroups, billingStatus, setFormEnterpriseGroups, dispatch, setBillingStatus])

  useEffect(() => {
    getEnterpriseFieldsData ? setEnterpriseCustomFields(getEnterpriseFieldsData) : setEnterpriseCustomFields({});
  }, [getEnterpriseFieldsData])
  
  useEffect(() => {
    if (enterprise) {
      getEnterpriseGroupsData && setFormEnterpriseGroups(Object.entries(getEnterpriseGroupsData?.['groups']).map(([key, value]) => {
        return {label: value, value: key}
      }));
    }
  }, [getEnterpriseGroupsData, enterprise, setFormEnterpriseGroups])

  useEffect(() => {
    enterpriseCustomFields && enterprise && Object.entries(enterpriseCustomFields).length > 0 ? setShowCustomFields(true) : setShowCustomFields(false);
  }, [enterpriseCustomFields, enterprise])

  return (
    <>
      <div
        className={`custom-card ${accordionStyle && (!addToEnterprise || !isHovered ? "enterprise-hover" : "")} ${darkMode ? "darkMode" : ""}`}
        id='enterprise-card'
        data-intercom-target={"add-to-enterprise-container"}
        onClick={() => {
          if (!addToEnterprise) {
            toggleAccordion()
          }
        }}
      >
      {!accordionStyle && <div className='enterprise-card-header mb-3'>Add Site to Enterprise</div>}
      {accordionStyle &&
        <>
          {addToEnterprise
            ?
              <div
                className={`d-flex justify-content-between enterprise-card-header ${addToEnterprise ? "enterprise-card-header-after" : "enterprise-card-header"}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  if (addToEnterprise) {
                    toggleAccordion()
                  }
                }}
              >
                <div>Add Site to Enterprise</div>
                <Button
                  onClick={toggleAccordion}
                  // className={`${!accordionStyle && 'd-none'}`}
                >
                  <Icon className={`fas ${addToEnterprise ? "fa-chevron-up" : "fa-chevron-down"} accordion-button`}></Icon>
                </Button>
              </div>
            :
              <Tooltip
                description="Add site to an enterprise? (optional)"
                placement="right"
              >
                <div
                  className={`d-flex justify-content-between enterprise-card-header ${addToEnterprise ? "enterprise-card-header-after" : "enterprise-card-header"}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    if (addToEnterprise) {
                      toggleAccordion()
                    }
                  }}
                >
                  <div>Add Site to Enterprise</div>
                  <Button
                    data-intercom-target={"add-to-enterprise-toggle"}
                    onClick={toggleAccordion}
                    className={`${!accordionStyle && 'd-none'}`}
                  >
                    <Icon className={`fas ${addToEnterprise ? "fa-chevron-up" : "fa-chevron-down"} accordion-button`}></Icon>
                  </Button>
                </div>
              </Tooltip>
          }
        </>
      }
          {addToEnterprise &&
            <Form.Group id="form-inputs">
              <Row>
                <Col
                  controlid="company_name_id"
                  className="col-12 col-sm-6 mb-3"
                >
                  <Form.Label>Enterprise Site Name *</Form.Label>
                  <Form.Control
                    data-intercom-target={"add-to-enterprise-site-name"}
                    type="text"
                    name="enterprise_site_name"
                    className="form-input"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value)
                      handleNullChange("enterprise_name", e.target.value)
                    }}
                  />
                </Col>
                <Col 
                  className="col-12 col-sm-6 mb-3"
                  controlid="site_type">
                  <Form.Label>Custom Site Ref</Form.Label>
                  <Form.Control
                    data-intercom-target={"add-to-enterprise-custom-site-ref"}
                    type="text"
                    name="custom_site_ref"
                    className="form-input"
                    value={custom_site_ref}
                    onChange={(e) => {setCustomSiteRef(e.target.value)}}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Group 
                  className="col-6 mb-3"
                  controlId="site_type">
                  <div>
                    <Form.Label>Enterprise *</Form.Label>
                    {loadingEnterpriseList && <Loading />}
                  </div>
                  <Select
                    data-intercom-target={"add-to-enterprise-enterprise-select"}
                    name="enterprises"
                    options={formEnterprises.sort((a, b) => a.label.localeCompare(b.label))}
                    required={addToEnterprise}
                    value={
                      enterprise 
                      ?
                      formEnterprises.find(option => option.value === enterprise) 
                      : null
                    }
                    isClearable
                    maxMenuHeight={400}
                    onChange={(e) => {
                      setEnterprise(e ? e.value : null)
                      handleNullChange("enterprises", e ? e.value : null)
                    }}
                    styles={darkMode ? darkModeSelectStyles : ""}
                  />
                  {!loadingEnterpriseList &&
                    <Row>
                    <Form.Control.Feedback type="warning" className="trigger-feedback-invalid col p-0">
                      <div className={`feedback-container-${formEnterprises.length <= 0 ? "after" : "before"}`}>
                          <div className="d-flex align-items-center flex-wrap justify-content-between">
                            <div className="feedback-text-stack col-9">
                              There are no enterprises associated with this company.
                              <div className="mt-1 p-0">Contact the team to learn more.</div>
                            </div>
                            <Button className="feedback-button col-3" variant="primary" data-intercom-target={"open-chat-button-add-site"}>Open Chat</Button>
                          </div>
                      </div>
                    </Form.Control.Feedback>
                  </Row>}
                </Form.Group>
                <Form.Group
                  className="col-6 mb-3"
                  controlId="site_type">
                  <Form.Label>Billing Status *</Form.Label>
                  <Select
                    data-intercom-target={"add-to-enterprise-billing-status-select"}
                    name="billing_status"
                    options={formBillingStatus.sort((a, b) => a.label.localeCompare(b.label))}
                    value={
                      billingStatus 
                      ?
                        formBillingStatus.find(option => option.value === billingStatus) 
                      : ""
                    }
                    // value={formBillingStatus.find(option => option.value === billingStatus)}
                    isClearable
                    maxMenuHeight={400}
                    onChange={(e) => {
                      setBillingStatus(e ? e.value : "")
                      handleNullChange("billing_status", e ? e.value : null)
                    }}
                    styles={darkMode ? darkModeSelectStyles : ""}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Col 
                  className="col-12 col-sm-6 mb-3"
                  controlid="site_type">
                  <Form.Label>Group</Form.Label>
                  {enterprise
                  ?
                    <Select
                      data-intercom-target={"add-to-enterprise-group-select"}
                      name="enterprise_groups"
                      options={formEnterpriseGroups.sort((a, b) => a.label.localeCompare(b.label))}
                      value={
                      enterprise_groups 
                      ?
                        formEnterpriseGroups.find(option => option.value === enterprise_groups) 
                      : ""
                    }
                      isClearable
                      maxMenuHeight={400}
                      onChange={(e) => {
                        setEnterpriseGroups(e ? e.value : null)
                      }}
                      styles={darkMode ? darkModeSelectStyles : ""}
                    />
                  :
                    <Select
                      data-intercom-target={"add-to-enterprise-group-select"}
                      classNamePrefix="edit-group-modal-parent-select"
                      name="enterprise_groups_no_enterprise"
                      className="no-click"
                      value={""}
                    />
                  }
                </Col>
              </Row>
              
              {/* Custom Fields Header */}
              <Form.Text className="custom-card-header">Enterprise Fields</Form.Text>

              {!showCustomFields && <Form.Text className="font-italic">This enterprise has no custom fields</Form.Text>}

              {showCustomFields &&
                mapCustomFields(enterpriseCustomFields, setEnterpriseCustomFieldsData)
              }

            </Form.Group>
          }
      </div>
    </>
  )
}

export default AddToEnterpriseElement;