import {
  getRepresentativeOccurrence
} from "services/redux/selectors/reports/scheduled";

export function AreaColumn({ columns, eventTextFilter, getEventMeta }) {
  const column = columns["AREA"];
  return {
    ...column,

    accessor: site => {
      const occurrence = getRepresentativeOccurrence({
        occurrences: site.occurrences,
        eventTextFilter,
        getEventMeta
      });

      return occurrence.area;
    }
  };
}