import React, { useEffect } from 'react';
import Alert from "components/Common/Alert";

function SaveSiteSuccess({ showAlert, setShowAlert, handleAddShow, inputTextMain, inputTextButton}) {

  useEffect(() => {
    if (showAlert) {
      Alert({
        text: `${inputTextMain}`,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: `${inputTextButton}`
      }).then((result) => {
        if (result.isConfirmed) {
          setShowAlert(false);
          handleAddShow();
        } else {
          setShowAlert(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert])

  return (
    <>
    </>
  )
}

export default SaveSiteSuccess
