import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import DataTableManual from "components/Common/DataTableManual/DataTableManual";

import CompanyType from "../CompanyType";

import { loadCompaniesStart } from "services/redux/actions/companies";


class DataTable extends React.Component {

  render() {
    const { 
      companies,
      nameFilter,
      pagination,
      displayPageIndex,
      setDisplayPageIndex,
    } = this.props;

    let noDataMsg = "No data available...";

    const columns = [
      {
        key: "ID",
        Header: "Id",
        id: "id",
        width: 60,
        show: false,
        sortable: false,
        accessor: data => data.id,
      },
      {
        key: "LOGO",
        Header: "Logo",
        id: "logo_url",
        width: 120,
        resizable: false,
        sortable: false,
        accessor: data => data.logo_url,
        Cell: ({ row }) => {
          return row.logo_url
            ? <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50px" }}
              >
                <img
                  src={row.logo_url}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="" 
                />
              </div>
            : <div style={{ height: "50px" }}></div>
        },
      },
      {
        key: "NAME",
        Header: "Name",
        id: "name",
        width: 300,
        accessor: data => data.name,
        Cell: ({ row }) => {
          return <div style={{ fontSize: "15px", paddingLeft: "3px" }}>{row.name}</div>
        }
      },
      {
        key: "TYPE",
        Header: "Type",
        id: "type",
        accessor: data => data.type,
        Cell: ({ row }) => {
          return <CompanyType companyType={row.type} />
        }
      },
    ]

    return (
      <>
        <div className="card visual-data">
          <DataTableManual
            columns={columns}
            data={companies}
            loadDataAction={loadCompaniesStart}
            loadDataParams={{
              nameFilter: nameFilter,
            }}
            pages={pagination.pageCount}
            page={displayPageIndex} // keeps track of the page index that the table component displays (0-indexed)
            setDisplayPageIndex={setDisplayPageIndex}
            filterable={false}
            sortable={companies.length > 0}
            defaultSorted={[
              {
                id: "name",
                desc: false,
              },
            ]}
            defaultPageSize={20}
            noDataText={noDataMsg}
            getTrGroupProps={(state, rowInfo, column) => {
              return {
                className: "waves-effect",
                onClick: () => {
                  this.props.history.push(
                    `/companies/company/${rowInfo.row.id}`
                  );
                },
              };
            }}
          />
        </div>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    companies: state.companies.data.companies,
    pagination: state.companies.data.pagination,
  };
};
export default withRouter(connect(mapStateToProps)(DataTable));