import React from "react";
import { connect } from "react-redux";

import {
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import Select from 'react-select';

import { Button, Form } from "react-bootstrap";

import { setEnterpriseCallLimits } from "services/redux/actions";
import { setSOPCurrentContactStatus } from "services/redux/actions/sop";
import { initiateVoiceCall, setVoiceCallStatus, changeVoiceCallContactDetails } from "services/redux/actions/voiceCalls";

import { getCustomRef } from "services/redux/selectors/groups";

import { Tooltip } from "components/Common/Tooltip/";
import Alert from "components/Common/Alert";

import Icon from "components/Icons/Icon";

import moment from "services/locale/momentInit.js";

import _ from "lodash";

import conxtdOut from "apis/conxtdOut";

import "./Steps.scss";

class KeyholderStep extends React.Component {
  state = {
    activeKeyholder: null,
    activeKeyholderIndex: 0,
    isLastKeyholder: false,
    contactStatusOptions: [],
    updatingAction: false,
    movingToNextKeyholder: false,
    escalatingAction: false,
    deEscalatingAction: false,
    device: null, // voice call device
    token: null, // voice call token
  };

  componentDidMount() {
    const { enterprise, sop, step, voiceCalls } = this.props;

    // get the contact status options available to the keyholder
    const contactStatusType = step.type;
    const contactStatusList = sop.contactStatus[contactStatusType];
    const contactStatusOptions = [];
    for (const property in contactStatusList) {
      if (property !== "IP") {
        contactStatusOptions.push({ value: property, label: contactStatusList[property] })
      }
    }

    this.setState({ contactStatusOptions : contactStatusOptions });

    // find the current active keyholder for the step
    this.setActiveKeyholder();

    // get the current call limit for voice calls so the use can be informed if they need to top up
    if (voiceCalls) {
      const requestToken = conxtdOut.post(`/TwilioRequest/getCallLimit/${enterprise.id}`);

      requestToken.then((response) => {
        this.props.dispatch(
          setEnterpriseCallLimits({ 
            enterprise_id: enterprise.id,
            call_limits: response.data.call_limits,
          })
        );
      }).catch((error) => {
        console.log("Error on get call limits", error.message);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { step, keyholdersLoading } = this.props;
    if (
      !_.isEqual(step.sop_action.sop_actioned_logs, prevProps.step.sop_action.sop_actioned_logs)
      || (keyholdersLoading === false && prevProps.keyholdersLoading === true)
    ) {
      this.setActiveKeyholder();
    }

    if (this.state.activeKeyholder && (prevState.activeKeyholder === null)) {
      this.setCurrentContactStatus();
    }
  }

  setCurrentContactStatus() {
    const { step } = this.props;
    const { contactStatusOptions } = this.state;

    let currentContactStatus = null;

    if (step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0) {
      // has logs
      const lastLog = step.sop_action.sop_actioned_logs[step.sop_action.sop_actioned_logs.length - 1];
      
      if (lastLog.system_data) {
        // it is a keyholder log, so find out what the current contact status is
        const contactStatusOption = _.find(contactStatusOptions, ['value', lastLog.status]);
        if (contactStatusOption) { currentContactStatus = contactStatusOption };
      }
    }

    this.props.dispatch(
      setSOPCurrentContactStatus({
        currentContactStatus: currentContactStatus,
      })
    )
  }

  handleContactStatusChange = (selectedContactStatus) => {
    this.props.dispatch(
      setSOPCurrentContactStatus({
        currentContactStatus: selectedContactStatus,
      })
    );
  };

  setActiveKeyholder() {
    const { step, keyholders } = this.props;

    let activeKeyholder = null;
    let activeKeyholderIndex = 0;

    if (step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0) {
      // has logs
      const lastLog = step.sop_action.sop_actioned_logs[step.sop_action.sop_actioned_logs.length - 1];
      
      if (lastLog.system_data && keyholders) {
        // last log has system data, which we should be the keyholder data
        keyholders.forEach((keyholder, index) => {
          if (keyholder.id === lastLog.system_data.id) {
            activeKeyholder = keyholder;
            activeKeyholderIndex = index;
          }
        })
      } else if (keyholders && (keyholders.length > 0)) {
        // last log does not have system data, which means it's the final Escalate log,
        // so make the active keyholder the one from the previous log, if there is one.
        // (if there isn't it probably means there are no keyholders because if there were the first one would automatically be put into In Progress)
        // this keyholder will no longer be contactable as the user has moved on to the next step,
        // but the keyholder will be displayed in the greyed-out previous step just so you can see how it was left

        if (step.sop_action.sop_actioned_logs.length >= 2) {
          const secondLastLog = step.sop_action.sop_actioned_logs[step.sop_action.sop_actioned_logs.length - 2];

          keyholders.forEach((keyholder, index) => {
            if (secondLastLog.system_data && (keyholder.id === secondLastLog.system_data.id)) {
              activeKeyholder = keyholder;
              activeKeyholderIndex = index;
            }
          })
        }
      }
    } else if (keyholders && (keyholders.length > 0)) {
      // has no logs, so make the first keyholder the active keyholder
      activeKeyholder = keyholders[0];
      activeKeyholderIndex = 0;
    }

    let isLastKeyholder = false;
    if (!keyholders || (keyholders.length === 0) || (activeKeyholderIndex === keyholders.length-1)) { 
      isLastKeyholder = true;
    }

    this.setState({
      activeKeyholder: activeKeyholder,
      activeKeyholderIndex: activeKeyholderIndex,
      isLastKeyholder: isLastKeyholder,
    });
  }

  nextKeyholder(sopActionedEventId, sudoStateId, comment) {
    return new Promise(async (resolve, reject) => {

      const { keyholders, handleAmendAction, site, voiceCalls } = this.props;

      let activeKeyholder = this.state.activeKeyholder;
      let activeKeyholderIndex = this.state.activeKeyholderIndex;
      let isLastKeyholder = false;

      let nextOne = false;
      keyholders.forEach((keyholder, index) => {
        if (nextOne) {
          activeKeyholder = keyholder;
          activeKeyholderIndex = index;
          if (index === keyholders.length-1) {
            isLastKeyholder = true;
          }
          nextOne = false;
        } else if (keyholder.id === activeKeyholder.id) {
          nextOne = true;
        }
      });

      handleAmendAction(
        sopActionedEventId,
        sudoStateId,
        "C",
        "IP",
        activeKeyholder.id,
        comment,
        false
      ).then(() => {

        this.setState({
          activeKeyholder: activeKeyholder,
          activeKeyholderIndex: activeKeyholderIndex,
          isLastKeyholder: isLastKeyholder,
        });

        this.props.dispatch(
          setSOPCurrentContactStatus({
            currentContactStatus: null,
          })
        );

        if (voiceCalls && activeKeyholder && document.getElementById('call-controls-container').classList.contains("show")) {
          this.changeCallContactDetails(
            activeKeyholder.phone,
            activeKeyholder.uuid,
            activeKeyholder.name,
            activeKeyholder.id,
            site.name, 
            site.id,
            (site.id ? getCustomRef(site) : "")
          );
        }

        resolve();

      }).catch((error) => {
        reject(error);
      })
    })
  }

  nextStep(sopActionedEventId, comment) {
    return new Promise(async (resolve, reject) => {

      const { handleEscalateAction, voiceCalls } = this.props;

      handleEscalateAction(
        sopActionedEventId, 
        comment,
      ).then(() => {

        if (voiceCalls && document.getElementById('call-controls-container').classList.contains("show")) {
          document.getElementById('call-controls-container').classList.remove("show");

          this.props.dispatch(
            setVoiceCallStatus({
              callStatus: "idle"
            })
          );
        }

        resolve();

      }).catch((error) => {
        reject(error);
      })
    })
  }

  previousStep(sopActionedEventId, comment) {
    return new Promise(async (resolve, reject) => {

      const { handleDeEscalateAction, voiceCalls } = this.props;

      handleDeEscalateAction(
        sopActionedEventId, 
        comment,
      ).then(() => {

        if (voiceCalls && document.getElementById('call-controls-container').classList.contains("show")) {
          document.getElementById('call-controls-container').classList.remove("show");

          this.props.dispatch(
            setVoiceCallStatus({
              callStatus: "idle"
            })
          );
        }

        resolve();

      }).catch((error) => {
        reject(error);
      })
    })
  }

  initiateCall(phoneNumber, keyholderUuid, keyholderName, keyholderId, siteName, siteId, customRef) {
    if (phoneNumber !== "") {

      const { enterprise, groupId, sopActionedEventId, sudoStateId } = this.props;
      const { contactStatusOptions } = this.state;

      let phoneNumberInternational = phoneNumber;
      if (String(phoneNumberInternational[0]) === "0") {
        phoneNumberInternational = "+44" + String(phoneNumberInternational).substring(1);
      }

      // initiateVoiceCall sets the callStatus to "initiate".
      // componentDidUpdate on VoiceCallControls watches for this change in the callStatus,
      // and when that happens it calls makeCall() with the call options.
      // makeCall uses the Twilio device to start the call, and added the "show" class to call-controls-container to show the controls
      this.props.dispatch(
        initiateVoiceCall({ 
          phoneNumber: phoneNumberInternational,
          recordChannel: "record-from-answer-dual",
          keyholderUuid: keyholderUuid,
          keyholderName: keyholderName,
          siteName: siteName,
          siteId: siteId,
          customRef: customRef,
          origin: "sop",
          enterpriseId: enterprise.id,
          groupId: groupId,
          sopActionedEventId: sopActionedEventId,
          sudoStateId: sudoStateId,
          keyholderId: keyholderId,
          makingContactOption: _.find(contactStatusOptions, ['value', "P"]) // the contact status options for "Making Contact",
        })
      );
      
    }
  }

  changeCallContactDetails(phoneNumber, keyholderUuid, keyholderName, keyholderId, siteName, siteId, customRef) {
    if (phoneNumber !== "") {

      const { enterprise, groupId, sopActionedEventId, sudoStateId } = this.props;

      let phoneNumberInternational = phoneNumber;
      if (String(phoneNumberInternational[0]) === "0") {
        phoneNumberInternational = "+44" + String(phoneNumberInternational).substring(1);
      }

      this.props.dispatch(
        changeVoiceCallContactDetails({ 
          phoneNumber: phoneNumberInternational,
          recordChannel: "record-from-answer-dual",
          keyholderUuid: keyholderUuid,
          keyholderName: keyholderName,
          siteName: siteName,
          siteId: siteId,
          customRef: customRef,
          origin: "sop",
          enterpriseId: enterprise.id,
          groupId: groupId,
          sopActionedEventId: sopActionedEventId,
          sudoStateId: sudoStateId,
          keyholderId: keyholderId,
        })
      );
      
    }
  }

  render() {
    const {
      enterprise,
      site,
      sop,
      sopActionedEventId,
      sudoStateId,
      step,
      stepNumber,
      activeStep,
      closedAction,
      firstStep,
      lastStep,
      isAssignedUser,
      currentContactStatus,
      keyholders,
      keyholdersLoading,
      handleAmendAction,
      voiceCalls,
      callStatus,
      darkMode,
    } = this.props;

    const {
      activeKeyholder,
      activeKeyholderIndex,
      contactStatusOptions,
    } = this.state;

    const callIcon = (
      <Icon 
        className="fas fa-phone" 
        style={{
          position: "relative",
          right: "4px", 
          bottom: "4px",
        }}
      />
    );

    const { call_limits } = enterprise;

    let callLimitWarning = null;

    if (call_limits === null) {
      callLimitWarning = <div className={`row my-1 py-1 ${activeStep ? "bg-warning" : "bg-muted"} text-white justify-content-center sop-keyholder-message`}>Call feature setup required</div>
    } else if (call_limits === 90) {
      callLimitWarning = <div className={`row my-1 py-1 ${activeStep ? "bg-warning" : "bg-muted"} text-white justify-content-center sop-keyholder-message`}>Call limit at 90% usage</div>
    } else if (call_limits === 100) {
      callLimitWarning = <div className={`row my-1 py-1 ${activeStep ? "bg-danger" : "bg-muted"} text-white justify-content-center sop-keyholder-message`}>Call limit reached, please top up</div>
    };

    return (
      <VerticalTimelineElement
        id={`step-${stepNumber}`}
        className="sop-action-map-timeline-element vertical-timeline-element--work py-4 my-2"
        contentStyle={
          activeStep
            ? darkMode
              ? { border: "1px solid #bbb", backgroundColor: "#0f1f3e" }
              : { border: "1px solid #bbb" }
            : darkMode
              ? { border: "1px solid #bbb", backgroundColor: "#233358" }
              : { border: "1px solid #bbb", backgroundColor: "#eee" }
        }
        contentArrowStyle={{ borderRight: "7px solid #bbb" }}
        icon={<div className="step-number-icon">{stepNumber}</div>}
        iconStyle={{ background: "#2c3e50", marginTop: "2em" }}
      > 
        {/* step title */}
        <h6 className="vertical-timeline-element-title">{(step.code === "C1") ? step.custom_desc : step.step}</h6> 

        {/* step description */}
        {/* <div className="my-1">{step.custom_desc}</div>   */}

        {voiceCalls && callLimitWarning}
        
        {/* status logs */}
        {step.sop_action && step.sop_action.sop_actioned_logs && step.sop_action.sop_actioned_logs.length > 0 &&
          <div>
            <div className="mt-2" style={{ fontWeight: "bold" }}>Logs</div>
            {
              step.sop_action.sop_actioned_logs.map((actionedLog, actionedLogIndex) => {

                const logContactStatusKey = actionedLog.status_key; // e.g. 'default'
                const logContactStatuses = sop.contactStatus[logContactStatusKey];  // the contact status object associated with the contact key,
                                                                                    // e.g. { {IP: 'In Progress}, {CO : 'Complete'} }

                const logText = (actionedLog.system_data) // if there is system data, then it's relating to a keyholder
                                  ? `${actionedLog.system_data.name} - ${actionedLog.system_data.phone}`
                                  : logContactStatuses[actionedLog.status] // if no system data, it's probably the Escalate status

                const created = new moment(actionedLog.created);

                return (
                  <div key={actionedLogIndex}>
                    {/* status log */}
                    <div 
                      className="d-flex justify-content-between"
                      style={{borderBottom: "1px solid lightgray"}}
                    >
                      {/* show the text associated with the log - either 'In Progress' or the name of the Keyholder */}
                      <div>{logText}</div>
                      <div 
                        className="text-muted"
                        style={{minWidth: "130px", textAlign: "right"}}
                      >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                    </div>

                    {!actionedLog.system_data // no system data, so probably just the Escalate status
                      ?
                        // show the associated comments
                        actionedLog.sop_actioned_comments 
                        && actionedLog.sop_actioned_comments.length > 0 
                        && actionedLog.sop_actioned_comments.map((comment, commentIndex) => {

                          const created = new moment(comment.created);

                          let displayComment = comment.comment;
                          try {
                            displayComment = decodeURIComponent(displayComment)
                          } catch (ignore) {};

                          return (
                            <div 
                              key={actionedLogIndex + "-" + commentIndex}
                              className="ml-2 d-flex justify-content-between"
                              style={{borderBottom: "1px solid lightgray"}}
                            >
                              <div>{displayComment}</div>
                              <div 
                                className="text-muted"
                                style={{minWidth: "130px", textAlign: "right"}}
                              >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                            </div>
                          )
                        })
                      :
                        // system data, so this is relating to a keyholder.
                        // show the contact log status changes
                        actionedLog.sop_contact_logs.map((contactLog, contactLogIndex) => {
                          const created = new moment(contactLog.created);

                          return (
                            <div key={contactLogIndex}>
                              {/* contact status log */}
                              <div 
                                className="ml-2 d-flex justify-content-between"
                                style={{borderBottom: "1px solid lightgray"}}
                              >
                                {/* show the text associated with the contact status of the log, e.g. 'Making Contact' */}
                                <div>{logContactStatuses && logContactStatuses[contactLog.status]}</div>
                                <div 
                                  className="text-muted"
                                  style={{minWidth: "130px", textAlign: "right"}}
                                >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                              </div>

                              {/* associated comments */}
                              {contactLog.sop_contact_comments 
                                && contactLog.sop_contact_comments.length > 0 
                                && contactLog.sop_contact_comments.map((comment, commentIndex) => {

                                  const created = new moment(comment.created);

                                  let displayComment = comment.comment;
                                  try {
                                    displayComment = decodeURIComponent(displayComment)
                                  } catch (ignore) {};

                                  if (comment.comment) {
                                    return (
                                      <div 
                                        key={contactLogIndex + "-" + commentIndex}
                                        className="ml-4 d-flex justify-content-between"
                                        style={{borderBottom: "1px solid lightgray"}}
                                      >
                                        <div>{displayComment}</div>
                                        <div 
                                          className="text-muted"
                                          style={{minWidth: "130px", textAlign: "right"}}
                                        >{created.format("HH:mm:ss DD/MM/YYYY")}</div>
                                      </div>
                                    )
                                  } else {
                                    return null;
                                  }
                                })}
                            </div>
                          )
                        })  
                    }
                  </div>
                )
              })
            }
            {!closedAction && <hr />}
          </div>
        }

        {
          // form for amending action
          !closedAction && <Form onSubmit={async (event) => {
            event.preventDefault();

            if (!this.state.updatingAction && activeKeyholder && !(event.target.keyholderStatus.value === "" && event.target.comment.value === "")) {
              this.setState({ updatingAction: true });

              const amendActionButton = document.getElementById("sop-amend-action-button" + stepNumber);
              amendActionButton.classList.add("cursor-progress");

              const commentElement = document.getElementById("comment" + stepNumber);

              handleAmendAction(
                sopActionedEventId,
                sudoStateId,
                "A",
                event.target.keyholderStatus.value || step.sop_action.status, // take the contact status from the select box, 
                                                                              // but if it has no value, use the current contact status
                activeKeyholder.id,
                event.target.comment.value,
                false
              ).then(() => {

                commentElement.value = ""; 
                amendActionButton.classList.remove("cursor-progress");
                this.setState({ updatingAction: false });

              }).catch((error) => {
                console.log("Error on amend action: ", error.message);
                Alert({
                  text: "Issue updating action. Please try again later.",
                  icon: "warning",
                  showConfirmButton: true,
                });

                amendActionButton.classList.remove("cursor-progress");
                this.setState({ updatingAction: false });
              })
            }
          }}>

            {/* keyholders */}
            {!keyholdersLoading && keyholders.length === 0 &&
                <div className={`row my-1 py-1 ${activeStep ? "bg-warning" : "bg-muted"} text-white justify-content-center sop-keyholder-message`}>No contacts found</div> 
            }

            {!keyholdersLoading && activeKeyholder &&
              <div>
                <div className="my-2">Contact {activeKeyholderIndex + 1} of {keyholders.length}</div>
                <div className="my-2 d-flex justify-content-between align-items-center" key={stepNumber + "-" + activeKeyholder.id}>
                  <div className="d-flex justify-content-start align-items-center">
                    {voiceCalls && <div className="mr-2 call-button-container">
                      {
                        (activeStep && (callStatus === "idle" || callStatus === "ended") && (call_limits !== 100) && (call_limits !== null))
                          ? <Button
                              className="button-call"
                              variant="success"
                              onClick={() => {
                                this.initiateCall(
                                  activeKeyholder.phone,
                                  activeKeyholder.uuid,
                                  activeKeyholder.name,
                                  activeKeyholder.id,
                                  site.name, 
                                  site.id,
                                  (site.id ? getCustomRef(site) : "")
                                )
                              }}
                            >
                              {callIcon}
                            </Button>
                          : call_limits === 100
                            ?
                              <Tooltip 
                                placement="left"
                                description="Top up required"
                              >
                                <div>
                                  <Button
                                    className="button-call"
                                    variant="success"
                                    style={{pointerEvents: "none"}}
                                    disabled
                                  >
                                    {callIcon}
                                  </Button>
                                </div>
                              </Tooltip>
                            : call_limits === null
                              ?
                                <Tooltip 
                                  placement="left"
                                  description="Setup required"
                                >
                                  <div>
                                    <Button
                                      className="button-call"
                                      variant="success"
                                      style={{pointerEvents: "none"}}
                                      disabled
                                    >
                                      {callIcon}
                                    </Button>
                                  </div>
                                </Tooltip>
                              :
                                <Button
                                  className="button-call"
                                  variant="success"
                                  disabled
                                >
                                  {callIcon}
                                </Button>
                      }
                    </div>}
                    <div>
                      <div>{activeKeyholder.name}</div>
                      <div>{activeKeyholder.phone}</div>
                    </div>
                  </div>
                  <div style={{ width: "235px" }}>
                    {/* contact status  */}
                    {/* if there is more than one available contact status, allow the user to select */}
                    {contactStatusOptions.length > 1
                      ? <div>
                          <Select
                            id="keyholderStatus"
                            name="keyholderStatus"
                            classNamePrefix="sop-contact-status-select"
                            value={currentContactStatus}
                            onChange={(selectedContactStatus) => {
                              this.handleContactStatusChange(selectedContactStatus)
                            }}
                            options={contactStatusOptions}
                            isDisabled={!activeStep}
                          />
                        </div>
                      : contactStatusOptions.length === 1 && <input  
                          id="keyholderStatus"
                          type="hidden"
                          name="keyholderStatus"
                          value={contactStatusOptions[0].value}
                        />
                    }
                  </div>
                </div>
              </div>
            }

            {/* comment box */}
            <textarea
              id={"comment" + stepNumber} 
              name="comment" 
              rows="3"
              className="mt-1 form-control sop-comment"
              style={{width: "100%"}}
              disabled={!activeStep || !isAssignedUser}
            ></textarea>

            {/* amend buttons */}
            <div className="d-flex justify-content-between mt-2">

              {/* next keyholder */}
              {keyholders.length > 0 && 
                <Button 
                  id={"sop-next-keyholder-button" + stepNumber} 
                  className="sop-next-keyholder-button mx-1 btn-primary"  
                  disabled={
                    !activeStep
                    || !isAssignedUser
                    || this.state.isLastKeyholder
                    || !step.sop_action.continue
                    || (voiceCalls && (callStatus === "initiate" || callStatus === "initiated" || callStatus === "accepted"))
                  }
                  onClick={() => {
                    if (!this.state.movingToNextKeyholder) {

                      this.setState({ movingToNextKeyholder: true });

                      const nextKeyholderButton = document.getElementById("sop-next-keyholder-button" + stepNumber);
                      nextKeyholderButton.classList.add("cursor-progress");

                      const commentElement = document.getElementById("comment" + stepNumber);
                      
                      this.nextKeyholder(
                        sopActionedEventId,
                        sudoStateId,
                        commentElement.value,
                      ).then(() => {

                        commentElement.value = "";
                        nextKeyholderButton.classList.remove("cursor-progress");
                        this.setState({ movingToNextKeyholder: false });

                      }).catch((error) => {
                        console.log("Error on move to next step: ", error.message);
                        Alert({
                          text: "Issue updating action. Please try again later.",
                          icon: "warning",
                          showConfirmButton: true,
                        });

                        nextKeyholderButton.classList.remove("cursor-progress");
                        this.setState({ movingToNextKeyholder: false });
                      })
                    }
                  }}
                >Next Contact</Button>
              }

              {/* amend action button */}
              {keyholders.length > 0 && 
                <Button 
                  id={"sop-amend-action-button" + stepNumber} 
                  className="sop-amend-action-button mx-1 btn-primary" 
                  type="submit" 
                  disabled={!activeStep || !isAssignedUser}
                >Update</Button>
              }
            </div>

            <div className="d-flex justify-content-between mt-2">
              {/* de-escalate (previous step) button */}
              <Button 
                id={"sop-previous-step-button" + stepNumber} 
                className={`sop-previous-step-button mx-1 btn-primary ${firstStep && "sop-button-hidden"}`}
                disabled={
                  !activeStep
                  || !isAssignedUser
                  || (voiceCalls && (callStatus === "initiate" || callStatus === "initiated" || callStatus === "accepted"))
                }
                onClick={() => {
                  const previousStepButton = document.getElementById("sop-previous-step-button" + stepNumber);
                  previousStepButton.disabled = true;
                  previousStepButton.classList.add("cursor-progress");

                  if (!this.state.deEscalatingAction) {
                    this.setState({ deEscalatingAction: true });

                    const commentElement = document.getElementById("comment" + stepNumber);
                    
                    this.previousStep(
                      sopActionedEventId, 
                      commentElement.value,
                    ).then(() => {

                      commentElement.value = "";
                      previousStepButton.classList.remove("cursor-progress");
                      this.setState({ deEscalatingAction: false });

                    }).catch((error) => {
                      console.log("Error on de-escalate action: ", error.message);
                      Alert({
                        text: "Issue updating action. Please try again later.",
                        icon: "warning",
                        showConfirmButton: true,
                      });

                      previousStepButton.classList.remove("cursor-progress");
                      previousStepButton.disabled = false;
                      this.setState({ deEscalatingAction: false });

                    })
                  } else {
                    previousStepButton.classList.remove("cursor-progress");
                    previousStepButton.disabled = false;
                  }
                }}
              >Previous Step</Button>

              {/* escalate (next step) button */}
              <Button 
                id={"sop-next-step-button" + stepNumber} 
                className={`sop-next-step-button mx-1 btn-primary ${lastStep && "sop-button-hidden"}`}
                disabled={
                  !activeStep
                  || !isAssignedUser
                  || !step.sop_action.continue
                  || (voiceCalls && (callStatus === "initiate" || callStatus === "initiated" || callStatus === "accepted"))
                }
                onClick={() => {
                  const nextStepButton = document.getElementById("sop-next-step-button" + stepNumber);
                  nextStepButton.disabled = true;
                  nextStepButton.classList.add("cursor-progress");

                  if (!this.state.escalatingAction) {
                    this.setState({ escalatingAction: true });

                    const commentElement = document.getElementById("comment" + stepNumber);
                    
                    this.nextStep(
                      sopActionedEventId, 
                      commentElement.value,
                    ).then(() => {

                      commentElement.value = "";
                      nextStepButton.classList.remove("cursor-progress");
                      this.setState({ escalatingAction: false });

                    }).catch((error) => {
                      console.log("Error on escalate action: ", error.message);
                      Alert({
                        text: "Issue updating action. Please try again later.",
                        icon: "warning",
                        showConfirmButton: true,
                      });

                      nextStepButton.classList.remove("cursor-progress");
                      nextStepButton.disabled = false;
                      this.setState({ escalatingAction: false });

                    })
                  } else {
                    nextStepButton.classList.remove("cursor-progress");
                    nextStepButton.disabled = false;
                  }
                }}
              >Next Step</Button>
            </div>
          </Form>
        }
      </VerticalTimelineElement>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    sop: state.sop,
    currentContactStatus: state.sop.currentContactStatus,
    keyholders: state.sites.current.keyholders.data,
    keyholdersLoading: state.sites.current.keyholders.loading,
    callStatus: state.voiceCalls.callStatus,
    darkMode: state.app.darkMode,
  };
};
export default connect(mapStateToProps)(KeyholderStep);