import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Card } from "react-bootstrap";

import AddToEnterpriseElement from "../../AddSingleSite/AddToEnterpriseElement";

// import _ from "lodash";
import conxtdOut from "apis/conxtdOut";

// import "../AssetEditor.scss";
import SingularAssociation from "./SingularAssociation";

function EnterpriseAssociations({ 
  arcmap_id,
  companyId,
  siteRef,
  invalidEnterprise,
  setInvalidEnterprise,
  setAddToEnterpriseData,
  sudoSiteId,
  initialiseFormDataTrigger,
  setInitialiseFormDataTrigger,
  windowSize,
  siteCount,
}) {

  const darkMode = useSelector((state) => state.app.darkMode)
  const associations = useSelector((state) => state.serviceDesk.enterpriseAssociations);

  const [enterpriseAssociations, setEnterpriseAssociations] = useState([]);
  const [addToEnterprise, setAddToEnterprise] = useState(true);

  const [name, setName] = useState("");
  const [custom_site_ref, setCustomSiteRef] = useState("");
  const [enterprise, setEnterprise] = useState("");
  const [billing_status, setBillingStatus] = useState("");
  const [enterprise_groups, setEnterpriseGroups] = useState("");
  const [enterpriseCustomFieldsData, setEnterpriseCustomFieldsData] = useState([]);
  // const [enterpriseSiteId, setEnterpriseSiteId] = useState(null);

  // populate form state
  const [formBillingStatus, setFormBillingStatus] = useState([]);
  const [formEnterprises, setFormEnterprises] = useState([]);
  const [formEnterpriseGroups, setFormEnterpriseGroups] = useState([]);
  const [loadingEnterpriseList, setLoadingEnterpriseList] = useState(false);

  const initialiseFormData = () => {
    setName("");
    setCustomSiteRef("");
    setEnterprise(null);
    setBillingStatus("");
    setEnterpriseGroups({});
    setEnterpriseCustomFieldsData([]);
    // setEnterpriseSiteId(null);
  }

  const mapCustomFields = (state, setState) => {
    const groupedFields = [];
    const entries = Object.entries(state);
    
    for (let i = 0; i < entries.length; i += 2) {
      groupedFields.push(entries.slice(i, i + 2));
    }

    const render = groupedFields.map((group, index) => (
      <div className="row" key={index}>
        {group.map(([key, value]) => (
          <Form.Group className="col-6" key={key}>
            <Form.Label>{value}</Form.Label>
            <Form.Control
              name={key}
              className='form-input'
              onChange={(e) => {
                const newVal = e.target.value;
                const fieldName = e.target.name;
                setState((prev) => {
                  const updatedFields = { field_id: fieldName, value: newVal };
                  const existingIndex = prev.findIndex(field => field.field_id === fieldName);
  
                  if (existingIndex !== -1) {
                    const updatedState = [...prev];
                    updatedState[existingIndex] = updatedFields;
                    return updatedState;
                  } else {
                    return [...prev, updatedFields];
                  }
                });
              }}
            />
          </Form.Group>
        ))}
      </div>
    ));

    return render;
  }

  const smallScreenModalLabels = () => {
    return (
      <div className='d-flex justify-content-between flex-nowrap'>
        <Form.Label className="col label-title-ent-name justify-content-start mb-0">Enterprise</Form.Label>
        <Form.Label className="col label-title justify-content-start mb-0">Site</Form.Label>
        <Form.Label className="col d-flex label-title justify-content-center mb-0">Actions</Form.Label>
      </div>
  )}

// USE EFFECT HOOKS --
useEffect(() => {
  if (initialiseFormDataTrigger) {
    initialiseFormData();
    setInitialiseFormDataTrigger(false);
  }
}, [initialiseFormDataTrigger, setInitialiseFormDataTrigger])

useEffect(() => {
  if (associations) {
    setEnterpriseAssociations(associations);
  }
}, [associations])

useEffect(() => {
  let isMounted = true;

  if (companyId && sudoSiteId && arcmap_id) {
    const getFrontendFormData = conxtdOut.get(`/ServiceDesk/getFrontendFormData/${companyId}`);
    setLoadingEnterpriseList(true);
    getFrontendFormData.then((response) => {
      if (isMounted) {
        const data = response.data?.[0];
        setFormBillingStatus(Object.entries(data['billingStatus']).map(([key, value]) => {
          return {label: value, value: key}
        }))
        setFormEnterprises(
          Object.entries(data['enterprises'])
            .filter(([key, value]) => 
              enterpriseAssociations.length > 0
                ? enterpriseAssociations[0].enterprise.id !== value
                : true
            )
            .map(([key, value]) => ({ label: key, value: value }))
        );
      }setLoadingEnterpriseList(false);
    }).catch((error) => {
      console.log(error);
      setLoadingEnterpriseList(false);
    })
    

    return () => {
      isMounted = false;
    };
  }
}, [companyId, arcmap_id, sudoSiteId, setFormEnterprises, setFormBillingStatus, enterpriseAssociations])

useEffect(() => {
  setAddToEnterpriseData({
    name,
    enterprise,
    custom_site_ref,
    billing_status,
    groups: enterprise_groups,
    enterprise_custom_fields: enterpriseCustomFieldsData,
  })
}, [
  name, 
  enterprise, 
  custom_site_ref, 
  billing_status, 
  enterprise_groups, 
  enterpriseCustomFieldsData, 
  setAddToEnterpriseData
])

  return (
    <>
      <div className='w-100' id='enterprise-associations-container' data-intercom-target={"enterprise-associations-container"}>
        <div className={`d-flex justify-content-center ${darkMode ? "darkMode" : ""} w-100 card-body p-0`}>
          <Row className="w-100">
            <Col className="p-0">
              <div>
                {enterpriseAssociations.length > 0 ?
                  <div id="enterprise-associations-card">
                    <Card className="enterprise-associations-header m-0 pt-2 pb-2">
                      {windowSize.width > 700 ?
                        <div className='d-flex justify-content-between flex-nowrap'>
                          <Form.Label className="col label-title-ent-name justify-content-start mb-0">{windowSize.width < 915 ? 'Enterprise' : 'Enterprise Name'}</Form.Label>
                          <Form.Label className="col label-title justify-content-start mb-0">{windowSize.width < 915 ? 'Site' : 'Site Name'}</Form.Label>
                          <Form.Label className="col label-title justify-content-start mb-0">Custom Ref</Form.Label>
                          <Form.Label className="col label-title justify-content-start mb-0">{windowSize.width < 915 ? 'Billing' : 'Billing Status'}</Form.Label>
                          <Form.Label className="col d-flex label-title justify-content-center mb-0">Actions</Form.Label>
                        </div>
                      :
                        windowSize.width > 500 ? 
                          smallScreenModalLabels() 
                        : 
                          <div className='d-flex justify-content-center flex-wrap flex-column pl-1 pr-1'>
                              <Form.Label className="label-title-ent-name-small mb-2">Associated Enterprise Sites: {siteCount}</Form.Label>
                              <p className="d-flex justify-content-center">Rotate mobile device to view Enterprise Associations table</p>
                          </div>
                      }
                    </Card>
                    {enterpriseAssociations && windowSize.width >= 500 ?
                      enterpriseAssociations.map((association, index) => (
                        <SingularAssociation
                          key={index}
                          enterpriseName={association.enterprise.name}
                          enterprise_id={association.enterprise.id}
                          group_id={association.enterprise_group.id}
                          siteName={association.name}
                          customRef={association.custom_site_id}
                          billingStatus={association.billing_status}
                          sudoSiteId={sudoSiteId}
                          siteRef={siteRef}
                          enterpriseSiteId={association.id}
                          enterpriseLogo={association.enterprise.logo_dir}
                          windowSize={windowSize}
                        />
                      ))
                    :
                      <></>
                    }
                  </div>
                :
                  <div id="enterprise-associations-card">
                    <Card className="enterprise-associations-header m-0 p-2">
                      <h6>No enterprise associations detected.</h6>
                    </Card>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </div>
        <div className="m-0 pb-3">
          <AddToEnterpriseElement
            accordionStyle={false}
            enterprise={enterprise}
            setEnterprise={setEnterprise}
            name={name}
            setName={setName}
            custom_site_ref={custom_site_ref}
            setCustomSiteRef={setCustomSiteRef}
            billingStatus={billing_status}
            setBillingStatus={setBillingStatus}
            enterprise_groups={enterprise_groups}
            setEnterpriseGroups={setEnterpriseGroups}
            enterpriseCustomFieldsData={enterpriseCustomFieldsData}
            setEnterpriseCustomFieldsData={setEnterpriseCustomFieldsData}
            invalidEnterprise={invalidEnterprise}
            setInvalidEnterprise={setInvalidEnterprise}
            formBillingStatus={formBillingStatus}
            formEnterprises={formEnterprises}
            addToEnterprise={addToEnterprise}
            setAddToEnterprise={setAddToEnterprise}
            mapCustomFields={mapCustomFields}
            formEnterpriseGroups={formEnterpriseGroups}
            setFormEnterpriseGroups={setFormEnterpriseGroups}
            initialiseFormDataTrigger={initialiseFormDataTrigger}
            setInitialiseFormDataTrigger={setInitialiseFormDataTrigger}
            loadingEnterpriseList={loadingEnterpriseList}
          />
        </div>
      </div>
    </>
  )
}

export default EnterpriseAssociations
