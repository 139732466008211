import get from "lodash/get";
import find from "lodash/find";

import EventFilter from "components/Enterprise/Reports/Types/Scheduled/EventFilter";

const overnight = {
  EXPECTED: { show: false },
  ACTUAL: {
    Header: "Recorded Time"
  },
  DIFFERENCE: { show: false },
  RESULT: {
    width: 160,
    Header: "Event",
    headerClassName: "event-type",
    Filter: EventFilter,
    filterType: "exact"
  },
  EVENT_TEXT: {
    width: 160
  },
  AREA: {
    Header: "Area",
    width: 90,
    id: "area",
    className: "area-column",
    Cell: ({ value }) => <span>{value}</span>,
    show: true,
  },
  ZONE: {
    Header: "Zone",
    width: 90,
    id: "zone",
    className: "zone-column",
    Cell: ({ value }) => <span>{value}</span>,
    show: true,
  },
  buildExportArray: ({
    data,
    getEventMeta,
    // getActualFromOccurrence
    // getDiffFromOccurrence
    getActualWithTimezone,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      // "Occurrences",

      "Event",
      // "Expected Time",
      "Recorded Time",
      // "Difference",
      "Event Text",
      "Area",
      "Zone",
      "Occurrences",
    ];

    // Add headers
    result.push(headers);

    data.forEach(row => {
      const occurrence = get(row, "result.0");
      const event = getEventMeta(occurrence);

      // Push site data first
      result.push([
        row["custom-id"] || "",
        row["site-name"],
        row.group || "",
        row["site-ref"] || "",
        // row.occurrences,

        event.label || "",
        // row["expected-time"] || "",
        // getActualFromOccurrence(get(row["actual-time"], 0)),
        getActualWithTimezone(get(row["actual-time"], 0)),
        // getDiffFromOccurrence(get(row["difference"], 0)),
        row["event-text"],
        occurrence.area || "",
        occurrence.zone || "",
        row.occurrences,
      ]);

      // And show occurrences below
      // const occurrences = get(row, "result");
      // occurrences.forEach((occurrence, index) => {
      //   const event = getEventMeta(occurrence);

      //   result.push([
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",

      //     event.label || "",
      //     // row["expected-time"] || "",
      //     getActualFromOccurrence(get(row["actual-time"], 0)),
      //     // getDiffFromOccurrence(get(row["difference"], 0)),
      //     row["event-text"],
      //     "#" + (index + 1)
      //   ]);
      // });
    });

    return result;
  },

  buildExportArrayFull: ({
    data,
    getEventMeta,
    getActualWithTimezone,
    sites,
  }) => {
    let result = [];

    let headers = [
      "Custom ID",
      "Site Name",
      "Group",
      "Site Ref",
      "Event",
      "Recorded Time",
      "Event Text",
      "Area",
      "Zone",
      "Occurrence",
    ];

    // Add headers
    result.push(headers);

    data.forEach(row => {
      const occurrence = get(row, "result.0");
      const event = getEventMeta(occurrence);

      // result.push([
      //   row["custom-id"] || "",
      //   row["site-name"],
      //   row.group || "",
      //   row["site-ref"] || "",
      //   event.label || "",
      //   getActualWithTimezone(get(row["actual-time"], 0)),
      //   row["event-text"],
      //   occurrence.area || "",
      //   occurrence.zone || "",
      //   row.occurrences,
      // ]);

      // Rows with each individual occurrence
      let siteData = find(sites, ["site_ref", row["site-ref"]]);
      if (siteData && siteData.occurrences) {
        siteData.occurrences.forEach((occurrence, index) => {
          const event = getEventMeta(occurrence);

          result.push([
            row["custom-id"] || "",
            row["site-name"],
            row.group || "",
            row["site-ref"] || "",
            event.label || "",
            getActualWithTimezone(occurrence),
            occurrence.text,
            occurrence.area || "",
            occurrence.zone || "",
            "#" + (index+1),
          ]);
        });
      }
    });

    return result;
  }
};



export default overnight;