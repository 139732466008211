import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import countryList from "react-select-country-list";
import { darkModeSelectStyles } from "components/Common/CustomStyles/darkModeSelectStyles";
import CopyToClipboard from "components/Site/SiteView/SiteOverview/Tabs/CopyToClipboard";

const CountriesDropdown = ({ 
  setCountryCode, 
  handleNull = true, 
  handleNullChange, 
  clipboard = false,
  editMode = true,
  country_code = false,
}) => {

  const [countryOptions] = useState(countryList().getData());
  const [formCountryCodes, setFormCountryCodes] = useState(countryOptions);
  const [topListCountries] = useState([
    { label: "United Kingdom", value: "GB" },
    { label: "Ireland", value: "IE" },
    { label: "Netherlands", value: "NL" },
    { label: "Belgium", value: "BE" },
    { label: "Denmark", value: "DK" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Norway", value: "NO" },
    { label: "Spain", value: "ES" },
    { label: "Sweden", value: "SE" },
  ]);
  const [countriesListGroups, setCountriesListGroups] = useState([]);
  const [countryPlaceholder, setCountryPlaceholder] = useState("");
  const [countryName, setCountryName] = useState("");

  const { darkMode } = useSelector((state) => state.app)

  useEffect(() => {
    countryOptions?.length > 0 && setFormCountryCodes(countryOptions);
  }, [countryOptions, setFormCountryCodes]);

  useEffect(() => {
      if (country_code) {
        const currentCountry = countryOptions.filter(country => country.value === country_code)[0];
        setCountryPlaceholder(currentCountry);
        setCountryName(currentCountry?.label);
      }
    }, [country_code, countryOptions, setCountryPlaceholder, setCountryName]);

    useEffect(() => {
      const topCountryCodes = topListCountries.map(country => country.value);
      setFormCountryCodes(topCountryCodes.length > 0 && countryOptions.filter(country => !topCountryCodes.includes(country.value)))
    }, [countryOptions, topListCountries, setFormCountryCodes]);

  useEffect(() => {
    if (topListCountries.length > 0) {
      const groupedOptions = [
        {
          label: 'Frequently Used',
          options: topListCountries,
        },
        {
          label: "_____________",
          isDisabled: true,
        },
        {
          options: formCountryCodes,
        },
      ];
      setCountriesListGroups(groupedOptions);
    } else {
      return formCountryCodes
    }
  }, [topListCountries, formCountryCodes]);

  return (
    <Form.Group
      className="col-6 detail-field"
      id="country">
      <div className="d-flex flex-row">
        <Form.Label>Country *</Form.Label>
        {(!editMode && countryName && clipboard) && <CopyToClipboard id={"country"} copyText={countryName} />}
      </div>
      <Select
        data-intercom-target={"country-select"}
        name="country_code"
        options={countriesListGroups}
        className={`${clipboard ? (!editMode ? "site-details-read-only" : "edit-mode") : ""}`}
        classNamePrefix="country-select"
        maxMenuHeight={clipboard ? 135 : 400}
        required
        value={country_code ? country_code : ""}
        placeholder={countryPlaceholder && countryPlaceholder.label}
        onChange={(e) => {
          setCountryCode(e ? e.value : null);
          handleNull && handleNullChange("country_code", e ? e.value : null)
        }}
        styles={darkMode ? darkModeSelectStyles : {}}
      />
    </Form.Group>
  )
}

export default CountriesDropdown